import Container from '@material-ui/core/Container';
import React from 'react';

import ButtonSound from './ButtonSound';
import GameLost from './GameLost';
import GameStateDisplay from './GameStateDisplay';
import QGenerator from './QGenerator'
import Question from './Question';
import QuestionInfo from './QuestionInfo';
import RoundWon from './RoundWon';
import Sharer from './Sharer';
import Storage from './storage';
import StorageData from './StorageData';

class ViewGame extends React.Component {

    state = { seconds: 30, points: 0, questionHistory: [] };
    stepper = null;
    countDownAudio = null;
    okAudio = null;
    wrongAudio = null;
    constructor(props) {

        super(props);

        this.state = {
            question: (new QGenerator()).getNextQuestion(),
            showGoToButton: false,
            points: 0,
            rounds: 1,
            running: true
        }

        this.countDownAudio = new Audio('sounds/sound-countdown-ending.wav')
        this.okAudio = new Audio('sounds/sound-success.wav')
        this.wrongAudio = new Audio('sounds/sound-wrong.wav')
    }

    componentDidMount() {
        this.startTimer()

        if (window.ga) {
            window.ga('set', 'page', "/game-start");
            window.ga('send', 'pageview');
        }
    }

    onTimer = () => {
        const remainingSeconds = this.state.seconds - 1
        this.setState({
            seconds: remainingSeconds
        });

        if (remainingSeconds <= 5 && (new Storage()).load('playSound', true) === true) {

            this.countDownAudio.play();
        }

        if (remainingSeconds <= 0) {
            clearInterval(this.stepper);
            this.stepper = null;
            console.log("game end");
            this.clickProof(this.state.question);
        }
    }
    startTimer() {

        this.setState({
            seconds: 30
        });

        this.stepper = setInterval(this.onTimer, 1000);
        this.props.history.push(`/spiel/round/${this.state.rounds}`);
    }

    getNewQuestion() {

        let questionHistory = this.state.questionHistory;
        questionHistory = (typeof questionHistory === 'undefined') ? [] : questionHistory;
        let question = (new QGenerator()).getNextQuestion();
        if (questionHistory.indexOf(question.question) === -1) {
            return question;
        }

        while (questionHistory.indexOf(question.question) !== -1) {

            question = (new QGenerator()).getNextQuestion();
            if (questionHistory.indexOf(question.question) === -1) {
                
                questionHistory.push(question.question);
                this.setState({
                    questionHistory: questionHistory
                })
                break;
            }
        }

        return question;
    }

    newRound = () => {

        this.setState({
            question: this.getNewQuestion(),
            showGoToButton: false,
            running: true,
            rounds: this.state.rounds + 1
        }, () => this.startTimer())


        if (window.ga) {
            window.ga('set', 'page', "/game-new-round");
            window.ga('send', 'pageview');
        }
        
    }

    newGame = () => {

        this.setState({
            question: (new QGenerator()).getNextQuestion(),
            questionHistory: [],
            showGoToButton: false,
            points: 0,
            rounds: 1,
            running: true
        }, () => this.startTimer())

        if (window.ga) {
            window.ga('set', 'page', "/game-new");
            window.ga('send', 'pageview');
        }
    }

    clickProof = (question) => {

        let isCorrect = false;
        let points = this.state.points;
        clearInterval(this.stepper);
        for (let i = 0; i < question.answers.length; i++) {

            if (question.answers[i].selected === true && question.answers[i].selected === question.answers[i].correct) {

                isCorrect = true;
                points = parseInt(this.state.points) + 10 + parseInt(this.state.seconds);
                break;
            }
        }

        this.setState({
            correct: isCorrect,
            running: false,
            points: points
        })
        if (isCorrect === false) {
            
            console.log(question.question);
            (new StorageData()).saveWrongQuestions(question.question);

            if ((new Storage()).load('playSound', true) === true){
                this.wrongAudio.play();
            }
            if (this.state.points > 0) {

                (new StorageData()).saveNew(this.state.points, this.state.rounds);
            }
        } else if ((new Storage()).load('playSound', true) === true) {

            this.okAudio.play();
        }

        if (window.ga) {
            window.ga('set', 'page', "/game-game-proof");
            window.ga('send', 'pageview');
        }
    }


    render() {
        return (
            <div className="ViewGame">
                {this.state.running === true
                    ? <Container maxWidth="sm">
                        <Question clickProof={this.clickProof} question={this.state.question} />
                        {/* <AdTagHori /> */}
                        <GameStateDisplay seconds={this.state.seconds} points={this.state.points} rounds={this.state.rounds} />
                    </Container>
                    : <Container maxWidth="sm">
                        {/* <AdTagHori /> */}
                        {this.state.correct === true
                            ? <div className="result">

                                <RoundWon points={this.state.points} rounds={this.state.rounds} />
                                
                                <QuestionInfo question={this.state.question} />

                                <ButtonSound sound="clack" color={"primary"} onClick={() => this.newRound()} label={"Nächste Frage"} />

                                {this.state.points > 0 && <div className="goto-button">
                                    <h3>Jetzt den Erfolg mit Freunden teilen</h3>
                                    <Sharer title={`Ich habe beim Autokennzeichen Quiz ${this.state.points} Punkte erreicht!`} url="https://www.autokennzeichen-quiz.de" />
                                </div>}
                            </div>
                            : <div className="result">
                                
                                <GameLost points={this.state.points} rounds={this.state.rounds} />
                                <QuestionInfo question={this.state.question} />

                                <ButtonSound sound="clack" color={"secondary"} onClick={() => this.newGame()} label={"Nochmal spielen"} />

                                <Sharer title="Das Autokennzeichen Quiz" url="https://www.autokennzeichen-quiz.de" />
                            </div>}
                    </Container>}

            </div>
        );
    }
}

export default ViewGame;
