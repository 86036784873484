import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

class ViewKennzeichen extends React.Component {


    render() {

        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });
        return (
            <div className="ViewKennzeichen">

                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h2" component="h2">
                            Weitere Informationen zu Autokennzeichen
                    </Typography>
                        <Typography variant="body2" component="p">
                            Das Autokennzeichen oder Nummernschild, wie das Kfz-Kennzeichen gern auch im Sprachgebrauch genannt wird, gibt es in Deutschland bereits seit den 1870er Jahren.
                            <br />
                            Allerdings in der Form, die wir hier heute kennen erst seit 1957. Zunächst 1956 auf die meisten der westlichen Bundesländer angewandt, zog das Saarland als letztes nach, als es an Deutschland angegliedert wurde.
                            <br />
                            In der DDR wurde bereits 1953 ein neues Beschilderungs-System eingeführt.
                            </Typography>

                        <Typography variant="body2" component="p">
                            Die Kennzeichen bestehen im wesentlichen aus den folgenden Bereichen:
                            <ul>
                                <li>Unterscheidungskennzeichen - bestehend aus ein bis drei Buchstaben - für die jeweilige Region / Kreis / Stadt</li>
                                <li>Erkennungsnummer - bestehend aus ein bis zwei Buchstaben sowie ein bis vier Zahlen - zur individuellen Vergabe</li>
                                <li>Gültigkeits-Plakette für AU / HU (Abgas-Untersuchung, Haupt-Untersuchung) </li>
                            </ul>
                            Seit 1994 wurden die Kennzeichen zu sogenannten Euro-Kennzeichen umgestellt. Seitdem findet sich an der linken Seite das Symbol der Europäischen Union. Das Kennzeichen Vergabe-System hat sich hierdurch nicht verändert.
                        </Typography>

                        <Typography variant="body2" component="p">
                            Angaben aus Wikipedia auf die wichtigsten Fakten zusammengefasst. Der Original Artikel befindet sich hier:
                            <a href="https://de.wikipedia.org/wiki/Kfz-Kennzeichen_(Deutschland)" target="_blank" rel="noopener noreferrer">Wikipedia</a>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default ViewKennzeichen;
