import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class ViewNotFound extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Seite nicht gefunden - DISG-Schnelltest</title>
                    <meta name="ROBOTS" content="NOINDEX,FOLLOW" />
                </Helmet>
                <h1>Seite nicht gefunden</h1>
                
                <p>Die von Ihnen angeforderte Url konnte nicht gefunden werden.</p>
                <p>
                    Entweder ist die Url falsch oder der Seiteninhalt nicht mehr aktiv.
                    Schauen Sie doch hier in unserer Übersicht ob Sie dort das passende finden.
                </p>
                <p>
                    <Link to="/">
                        <button>Zurück zur Startseite</button>
                    </Link>
                </p>
            </div>
        );
    }
}

export default ViewNotFound;
