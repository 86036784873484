import { QUESTIONS } from "../questions";

export default class QGenerator {

    districts = {};
    counties = {};
    cities = {};

    constructor() {

        this.walkThroughLib();
        // console.log(this)
    }

    walkThroughLib() {


        for (let i = 0; i < QUESTIONS.length; i++) {

            const q = QUESTIONS[i];
            if (!this.districts[q.district]) {
                this.districts[q.district] = q;
            }

            // if (this.counties.indexOf(q.county) === -1) {
            if (!this.counties[q.county]) {
                this.counties[q.county] = q;
                // this.counties.push(q.county)
            }

            if (!this.cities[q.region]) {
                this.cities[q.region] = q;
                // this.counties.push(q.county)
            }
            // if (this.cities.indexOf(q.region) === -1) {
            //     this.cities.push(q.region)
            // }
        }
    }

    getNextQuestion() {

        // zufällig ein item auswählen
        let item = this.findRandom([]);

        // zufällig eine frageart auswählen
        const num = this.getRandomInt(1,100);

        // if (num > 97) {
        //     item = this.genQWest(item);
        // }
        // else if (num > 93) {
        //     item = this.genQEast(item);
        // }
        // else if (num > 80) {
        //     item = this.genQNotCounty(item);
        // }
        // else if (num > 70) {
        //     item = this.genQCounty(item);
        // }
        // else if (num > 60) {
        //     item = this.genQRegion(item);
        // }
        
        if (num < 50) {
            item = this.genSignByDistrict(item);
        }
        else {
            item = this.genDistrictBySign(item);
        }

        // füllantworten dazu holen
        const filler1 =this.findRandom([item]);
        const filler2 =this.findRandom([item, filler1]);
        const filler3 =this.findRandom([item, filler1, filler2]);
        item.answers = this.shuffle([
            item, filler1, filler2, filler3
        ]);

        return item;
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    findRandom(excludes) {

        const item = QUESTIONS[Math.floor(Math.random() * QUESTIONS.length)];
        item.district = item.district.toUpperCase()
        item.region = item.region.toUpperCase()
        item.county = item.county.toUpperCase()
        item.correct = excludes.length === 0 ? true : false;
        item.selected = false;
        
        let isOk = true;
        for (let i=0; i<excludes.length; i++) {

            if (excludes[i].region === item.region ||
                excludes[i].sign === item.sign ||
                excludes[i].district === item.district) {
                    isOk = false;
                    break;
                }
        }
        
        if (isOk === false) {
            return this.findRandom(excludes)
        }

        return item;
    }

    genQEast(item) {
        item.question = `Welche Kennzeichen gehören zu den "neuen" Bundesländern?`;
        item.field = 'county';
        item.type = 'genQEast';
        return item;
    }

    genQWest(item) {
        item.question = `Welche Kennzeichen gehören zu den "alten" Bundesländern?`;
        item.field = 'county';
        item.type = 'genQWest';
        return item;
    }


    genQNotCounty(item) {

        item.question = `Welches Kennzeichen wird nicht in ${item.county} vergeben?`;
        item.field = 'county';
        item.type = 'genQNotCounty';
        return item;
    }

    genQCounty(item) {

        item.question = `Zu welchem Bundesland gehört ${item.sign}?`;
        item.field = 'county';
        item.type = 'genQEast';
        return item;
    }



    genQRegion(item) {

        item.question = `Zu welchem Ort gehört ${item.sign}?`;
        // const question2 = `${item.sign} wird in welchem Ort vergeben?`;
        item.field = 'region';
        item.type = 'genQRegion';
        return item;
    }

    genSignByDistrict(item) {

        item.question = `Welches Kennzeichen wird hier vergeben: ${item.region} (${item.district})?`;
        item.field = 'sign';
        item.type = 'genSignByDistrict';

        return item;
    }
    genDistrictBySign(item) {

        item.question = `In welchem Landkreis wird das Kennzeichen ${item.sign} vergeben?`;
        item.field = 'district';
        item.type = 'genDistrictBySign';
        return item;
    }

    genQNotDistrict(item) {

        item.question = `Welches Kennzeichen wird nicht in ${item.district} vergeben?`;
        item.field = 'district';
        item.type = 'genQNotDistrict';
        return item;
    }

}