import React from 'react';

class ViewEvaluation extends React.Component {
    render() {
        return (
            <div className="ViewEvaluation">
                Table of results
            </div>
          );
    }
}

export default ViewEvaluation;
