
const LOCAL_STORAGE_LIST = 'highscores';
const LOCAL_STORAGE_WRONGS = 'wrongs';

class StorageData {

    saveNew(score, rounds) {

        let loadedItems = this.loadAll(LOCAL_STORAGE_LIST);
        loadedItems.push({
            score: score, date: new Date(), rounds: rounds
        });

        this.save(LOCAL_STORAGE_LIST, loadedItems);
    }

    findQuestion(question) {

        let loadedItems = this.loadAll(LOCAL_STORAGE_WRONGS);
        for (let i=0; i<loadedItems.length; i++) {
            
            if (loadedItems[i][question] === question) {
                return loadedItems[i][question];
            }
        }

        return 0;
    }

    saveWrongQuestions(question) {

        let loadedItems = this.loadAll(LOCAL_STORAGE_WRONGS);
        let already = false;
        for (let i=0; i<loadedItems.length; i++) {
            
            if (loadedItems[i][question]) {
                loadedItems[i][question]+=1;
                already = true;
                break;
            }
        }

        if (already === false) {
            loadedItems.push({[question]: 1})
        }
        


        this.save(LOCAL_STORAGE_WRONGS, loadedItems);
    }

    save(saveType, items) {

        console.log(saveType, items);

        localStorage.setItem(
            saveType, 
            JSON.stringify(items)
        );
    }

    loadAll(loadType) {

        const loadedIems = JSON.parse(
            localStorage.getItem(loadType)
        );

        if(loadedIems === null) { 
            return [];
        }

        return loadedIems;
    }
}
export default StorageData;