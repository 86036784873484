import Switch from '@material-ui/core/Switch';
import React from 'react';

import Storage from './storage';

class GameStateDisplay extends React.Component {

    state = {playSound: true}
    constructor(props) {

        super(props);

        this.state = {
            username: '',
            playSound: (new Storage()).load('playSound', true)
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked }, () => {

            (new Storage()).save('playSound', this.state.playSound)
        });
      };

    render() {
        return (
            <div className="GameStateDisplay">
                <div className="box left">
                    <span className="points">{this.props.rounds}. Runde</span> |
                    <span className="points">{this.props.points} Punkte</span> |
                    <span className="seconds">{this.props.seconds} Sekunden</span>
                </div>
                <div className="box right">
                    <Switch
                        checked={this.state.playSound}
                        onChange={this.handleChange}
                        color="primary"
                        name="playSound"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
                <div className="clearer" />
            </div>
        );
    }
}

export default GameStateDisplay;
