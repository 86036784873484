import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import CookieNotice from './components/CookieNotice';
import Footer from './components/Footer';
import Header from './components/Header';
import Highscores from './components/Highscores';
import ModalViewDatenschutz from './components/ModalViewDatenschutz';
import ModalViewInfo from './components/ModalViewInfo';
import Tracking from './components/Tracking';
import ViewEvaluation from './components/ViewEvaluation';
import ViewGame from './components/ViewGame';
import ViewHome from './components/ViewHome';
import ViewKennzeichen from './components/ViewKennzeichen';
import ViewNotFound from './components/ViewNotFound';
import ViewRegister from './components/ViewRegister';
import ViewRegisterRegion from './components/ViewRegisterRegion';
import ViewSearch from './components/ViewSearch';

class App extends React.Component {

  render() {

    const isWeb = (window.navigator.userAgent.indexOf("flutter_app") !== -1) ? false : true;

    return (
      <div className="App">
        <Router>
          <Header />
          <Switch>

            <Route path={'/resultat'} component={ViewEvaluation} />
            <Route path={'/highscores'} component={Highscores} />
            <Route path={'/suche/suchbegriff/:slug'} component={ViewSearch} />
            <Route path={'/kennzeichen'} component={ViewKennzeichen} />
            <Route path={'/register/county/:slug'} component={ViewRegisterRegion} />
            <Route path={'/register*'} component={ViewRegister} />

            <Route path={'/info'} component={ModalViewInfo} />
            <Route path={'/spiel*'} component={ViewGame} />
            <Route path={'/datenschutz'} component={ModalViewDatenschutz} />
            <Route path={'/'} component={ViewHome} />
            <Route path="*" component={ViewNotFound} />
          </Switch>
          <Footer />
          {isWeb === true && <CookieNotice />}
          <Tracking />
        </Router>
        
      </div>
    );
  }
}

export default App;
