import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


class GameLost extends React.Component {

    render() {

        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>Ende</Typography>
                    <Typography variant="h5" component="h2">Das war leider falsch!</Typography>
                    <Typography variant="body2" component="p">
                        <p><strong>Runde</strong> Nr: {this.props.rounds}</p>
                        <p><strong>Endstand</strong>: {this.props.points} Punkte</p>
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default GameLost;
