import React from 'react';
import ButtonSound from './ButtonSound';

class Answers extends React.Component {

    click(answer, index) {

        this.props.onClickAnswer(answer, index);
    }

    render() {

        return (
            <div className="Answers">
                {this.props.answers.map((answer, index) => {

                    const answerStatus = answer.selected ? 'primary' : '';
                    const label = (this.props.answerType === 'genDistrictBySign') ? answer.district : answer.sign;

                    return (
                        <div key={index} className="answerWrapper">
                        <ButtonSound sound="button" color={answerStatus} onClick={() => this.click(answer, index)} label={label} />
                        </div>
                    )
                })}
            </div>
          );
    }
}

export default Answers;
