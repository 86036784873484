import Container from '@material-ui/core/Container';
import React from 'react';

class Highscores extends React.Component {

    state = {highscoreData: []}
    constructor(props) {

        super(props);

        const rawScores = localStorage.getItem("highscores");
        const highscoreData = (rawScores == null) ? [] : this.orderByPoints(JSON.parse(rawScores))
        this.state = {
            highscoreData: highscoreData
        }
    }

    orderByPoints(objs) {

        const sorted = objs.sort((a,b) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0)); 

        const reverse = sorted.reverse();

        return reverse.slice(0, 20)
    }

    render() {
        return (
            <div className="Highscores">
                <Container maxWidth="sm">
                <h3>Highscores</h3>
                {this.state.highscoreData.length === 0 ? <div>Leider noch keine Daten vorhanden</div> : <div>
                    {this.state.highscoreData.map((entry, index) => {

                        const theDate = entry.date.substring(0,10);

                        return (
                            <div key={index}>
                                <strong>{entry.score}</strong>, am {theDate} in {entry.rounds} Runden
                            </div>
                        )
                    })}
                </div>}
                </Container>
            </div>
        );
    }
}

export default Highscores;
