

export default class Storage {

    save(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    load(key, fallbackValue) {

        const value = localStorage.getItem(key);
        if (value !== null) {

            return JSON.parse(value)
        }

        return fallbackValue;
    }
}