import * as React from 'react';
import Modal from 'react-modal';

import CookieConsent from 'react-cookie-consent';
import Link from '@material-ui/core/Link';
import ModalViewDatenschutz from './ModalViewDatenschutz';
const MODAL_DATENSCHUTZ = 'DATENSCHUTZ';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class CookieNotice extends React.Component {
    stat = { modalIsOpen: false };
    constructor(props) {
        super(props);

        this.state = {
            modalState: '',
            modalIsOpen: false
        };
    }

    closeModal = () => {
        this.setState({ showModal: false, isOpen: false });
    }
    showModal = (modal) => {

        console.log('showModal', modal);

        this.setState({
            modalState: modal,
            showModal: true,
            isOpen: true
        }, () => console.log("state", this.state));
    }

    afterOpenModal = () => {
        console.log('afterOpenModal');
    }

    render() {
        return (
            <CookieConsent
                location="bottom"
                buttonText="Verstanden"
                cookieName="disgNoticeCookie"
                style={{ background: 'rgb(230, 230, 230)', color: '#2FA4E7' }}
                buttonStyle={{ background: '#2FA4E7', color: '#fff', fontSize: '14px' }}
                expires={150}
            >
                Diese Website benutzt Cookies und blendet Werbung ein.
                Wenn Sie die Website weiter nutzen,
                gehen wir von Ihrem Einverständnis der Nutzung aus.
                Alles weitere unter <Link onClick={() => this.showModal(MODAL_DATENSCHUTZ)}>Datenschutz</Link>
                <Modal
                    isOpen={this.state.showModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {this.state.modalState === MODAL_DATENSCHUTZ && <ModalViewDatenschutz />}
                </Modal>
            </CookieConsent>
        );
    }
}

export default CookieNotice;
