import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    render() {
        return (
            <footer>
                <Typography>
                    <Link to="/">Start</Link>
                    <Link to="/kennzeichen">Kennzeichen</Link>
                    <Link to="/register">Register</Link>
                    <Link to="/datenschutz">Datenschutz</Link>
                    <Link to="/info">Info</Link>
                    <Link to="/highscores">Highscores</Link>
                </Typography>
            </footer>
        );
    }
}

export default Footer;
