import React from 'react';

class ModalViewInfo extends React.Component {
    render() {
        return (
            <div className="ModalViewInfo">
                <h1>Informationen</h1>
                <div className="content-wrapper">
                    <p>
                        Viebrock Software<br />
                        Im Winkel 6a<br />
                        21244 Buchholz<br />
                        Niedersachsen<br />
                        Deutschland<br />
                        E-Mail: office@viebrock-software.de<br />
                        <br /><br />
                        Steuernummer: 15/145/15455
                    </p>
                </div>



                <div className="content-wrapper">
                    <h2>Verwendete Medien</h2>
                    <p>Alle Bilder die hier eingesetzt werden, sind von Pixabay.com, frei für kommerzielle Nutzung</p>
                    <p>Sounds sind von https://freesound.org/, ebenfalls frei für kommerzielle Nutzung</p>
                </div>

                <div className="content-wrapper">
                    <h2>Haftung für Links</h2>
                    <p>Diese Seite/Applikation enthält Links zu externen Webseiten Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.</p>
                </div>

            </div>
        );
    }
}

export default ModalViewInfo;
