import Container from '@material-ui/core/Container';
import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {

    state = { active: 1 }

    constructor(props) {

        super(props);

        this.state = { active: 1 }
    }

    render() {

        return (
            <header>
                <Container maxWidth="sm">
                    <Link to="/">
                        <h1>
                            <img src="/kennzeichen-raten-logo.png" alt="Logo autokennzeichen-quiz.de" />
                        </h1>
                    </Link>
                </Container>
            </header>
        );
    }
}

export default Header;
