import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import React from 'react';
import { Link } from 'react-router-dom';

import Sharer from './Sharer';

class ViewHome extends React.Component {

    state = { search: ''}

    onChangeSearch = (evt) => {

        this.setState({ search: evt.target.value})
    }

    onClick = () => {

        console.log('onClick', this.state.search)
        this.props.history.push(`/suche/suchbegriff/${this.state.search}`)
    }

    render() {
        return (
            <div className="ViewHome">

                <Container maxWidth="sm">
                    <p>Kennst du alle <Link to="/kennzeichen">Kennzeichen</Link> aus Niedersachsen oder der Sachsen-Ahnhalt? Wer kennt denn schon alle deutschen Autokennzeichen? Wer weiß darüber hinaus, zu welchem Bundesland oder gar Landkreis die einzelnen Kürzel gehören?</p>
                    <p>Seit der letzten Kennzeichen-Reform sind knapp 70 alte, bereits ausgemusterte Autokennzeichen für kleinere Regionen und Altkreise, wieder da und machen es somit nicht einfacher, alle zu kennen.<br /> Eine Übersicht findest du aber auch Hier: <Link to="/register">Register</Link></p>
                    <p>Mit diesem Quiz findest du es heraus, wie gut es um dein Wissen bestellt ist.<br />Aber keine Sorge, durch den Endlos-Fragen-Modus kannst du dein Wissen auch gut verbessern und das Quiz als Lerneinheit nutzen.</p>
                </Container>

                <Container maxWidth="sm">
                    <label>Suche nach einem Kennzeichen?</label>
                    <input type="text" name="search" id="search" onChange={this.onChangeSearch} />
                    <button onClick={this.onClick}>Suchen</button>
                </Container>

                <Container maxWidth="sm">
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                    </div>
                </Container>

                <Container maxWidth="sm">
                    <h3>Wie funktioniert das Quiz?</h3>
                    <p>Über den Start Button wird das Spiel gestartet. Es gilt die angezeigte Frage richtig zu beantworten in dem alle gesuchten Antworten angeklickt wurden.<br />Ob du mit deiner Auswahl richtig gelegen hast, erfährst du dann auf der Folgeseite.</p>
                    <p><strong>Zeit: </strong>Für die Beantwortung jeder Frage hat man 30 Sekunden Zeit. Läuft Die Zeit ab, wird das genommen was ausgewählt ist.</p>
                    <p><strong>Punkte: </strong>Für eine korrekte Antwort gibt es 10 Punkte sowie die restlichen Sekunden oben drauf.</p>
                    <p><strong>Spielende:</strong>Wenn eine Frage nicht richtig oder gar nicht beantwortet wurde, ist das Spiel beendet</p>

                    <p><strong>Highscore:</strong>Das Spiel speichert im Storage deine Erfolge. So kannst du sehen wie sich deine Punkte entwickeln - und die ggf. Mit Freunden vergleichen</p>
                </Container>

                <Container maxWidth="sm">

                    {/* <EnterUsername /> */}
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                        <Sharer title="Das Autokennzeichen Quiz" url="https://www.autokennzeichen-quiz.de" />
                    </div>
                </Container>

                {/* <AdTagHori /> */}
            </div>
        );
    }
}

export default ViewHome;
