import React from 'react';
import Button from '@material-ui/core/Button';
import Storage from './storage';

export default class ButtonSound extends React.Component {

    onClick = () => {

        if ( (new Storage()).load('playSound', true) === true) {
            (new Audio(`sounds/sound-${this.props.sound}.wav`)).play();
        }
        
        this.props.onClick()
    }

    render() {

        const color = (this.props.color !== '') ? this.props.color : 'default';

        return(
            <Button variant="contained" color={color} onClick={this.onClick}>{this.props.label}</Button>
        );
    }
}