import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { QUESTIONS } from "../questions";

class ViewRegisterRegion extends React.Component {

    state = {
        districts: {},
        counties: {},
        cities: {}
    }

    componentWillMount() {

        this.walkThroughLib();
    }

    walkThroughLib() {

        let counties = {}
        for (let i = 0; i < QUESTIONS.length; i++) {

            const q = QUESTIONS[i];

            if (!counties[q.county]) {
                counties[q.county] = []
            }
            counties[q.county].push(q)

        }

        this.setState({
            counties: counties
        })
    }

    renderRegister() {

        const county = this.props.match.params.slug;

        let signCodes = []
        for (let i = 0; i < this.state.counties[county].length; i++) {

            signCodes.push(this.state.counties[county][i])
        }



        return (
            <ul>
                {signCodes.map((district, index) => {
                    return (
                        <li key={index} id={`sign-${district.sign}`}>
                           <strong>Kennzeichen: {district.sign}</strong><br />
                           <p>
                               Bundesland: {district.county}<br />
                               Kreis, Stadt: {district.region}<br />
                           </p>
                        </li>
                    )
                })
                }
            </ul >
        )

        // return (
        //     <div dangerouslySetInnerHTML={{__html: countyCodes.join("<hr />")}} />
        // )
    }

    render() {
        const county = this.props.match.params.slug;
        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });
        return (
            <div className="ViewKennzeichen">

                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h2" component="h2">
                            Register über alle Städte, Landkreise und ihre Kennzeichen
                            im Bundesland {county}
                    </Typography>
                        <Typography variant="body2" component="p">
                            {this.renderRegister()}
                        </Typography>
                    </CardContent>
                </Card>

                <Container maxWidth="sm">
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ViewRegisterRegion;
