export const QUESTIONS = [
  {
    "sign": "A",
    "region": "Augsburg",
    "district": "Stadt Augsburg, Landkreis Augsburg",
    "county": "Bayern"
  },
  {
    "sign": "AA",
    "region": "AAlen",
    "district": "Ostalbkreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "AB",
    "region": "AschaffenBurg",
    "district": "Stadt Aschaffenburg, Landkreis Aschaffenburg",
    "county": "Bayern"
  },
  {
    "sign": "ABG",
    "region": "AltenBurG",
    "district": "Landkreis Altenburger Land",
    "county": "Thüringen"
  },
  {
    "sign": "ABI",
    "region": "Anhalt, BItterfeld",
    "district": "Landkreis Anhalt-Bitterfeld",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "AC",
    "region": "AaChen",
    "district": "Städteregion Aachen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "AE",
    "region": "AuErbach",
    "district": "Vogtlandkreis",
    "county": "Sachsen"
  },
  {
    "sign": "AH",
    "region": "AHaus",
    "district": "Kreis Borken",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "AIB",
    "region": "AIBling",
    "district": "Landkreis München, Landkreis Rosenheim",
    "county": "Bayern"
  },
  {
    "sign": "AIC",
    "region": "AIChach",
    "district": "Landkreis Aichach-Friedberg",
    "county": "Bayern"
  },
  {
    "sign": "AK",
    "region": "AltenKirchen",
    "district": "Landkreis Altenkirchen (Westerwald)",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "ALF",
    "region": "ALFeld",
    "district": "Landkreis Hildesheim",
    "county": "Niedersachsen"
  },
  {
    "sign": "ALZ",
    "region": "ALZenau",
    "district": "Landkreis Aschaffenburg",
    "county": "Bayern"
  },
  {
    "sign": "AM",
    "region": "AMberg",
    "district": "Stadt Amberg",
    "county": "Bayern"
  },
  {
    "sign": "AN",
    "region": "ANsbach",
    "district": "Stadt Ansbach, Landkreis Ansbach",
    "county": "Bayern"
  },
  {
    "sign": "ANA",
    "region": "ANnAberg",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "ANG",
    "region": "ANGermünde",
    "district": "Landkreis Uckermark",
    "county": "Brandenburg"
  },
  {
    "sign": "ANK",
    "region": "ANKlam",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "AÖ",
    "region": "AltÖtting",
    "district": "Landkreis Altötting",
    "county": "Bayern"
  },
  {
    "sign": "AP",
    "region": "APolda",
    "district": "Landkreis Weimarer Land",
    "county": "Thüringen"
  },
  {
    "sign": "APD",
    "region": "APolDa",
    "district": "Landkreis Weimarer Land",
    "county": "Thüringen"
  },
  {
    "sign": "ARN",
    "region": "ARNstadt",
    "district": "Ilm-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "ART",
    "region": "ARTern",
    "district": "Kyffhäuserkreis",
    "county": "Thüringen"
  },
  {
    "sign": "AS",
    "region": "Amberg, Sulzbach",
    "district": "Landkreis Amberg-Sulzbach",
    "county": "Bayern"
  },
  {
    "sign": "ASL",
    "region": "ASchersLeben",
    "district": "Salzlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "ASZ",
    "region": "Aue, SchwarZenberg",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "AT",
    "region": "AltenTreptow",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "AU",
    "region": "AUe",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "AUR",
    "region": "AURich",
    "district": "Landkreis Aurich",
    "county": "Niedersachsen"
  },
  {
    "sign": "AW",
    "region": "AhrWeiler",
    "district": "Landkreis Ahrweiler",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "AZ",
    "region": "AlZey",
    "district": "Landkreis Alzey-Worms",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "AZE",
    "region": "Anhalt-ZErbst",
    "district": "Landkreis Anhalt-Bitterfeld",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "B",
    "region": "Berlin",
    "district": "Berlin",
    "county": "Berlin"
  },
  {
    "sign": "BA",
    "region": "BAmberg",
    "district": "Stadt Bamberg, Landkreis Bamberg",
    "county": "Bayern"
  },
  {
    "sign": "BAD",
    "region": "BADen-Baden",
    "district": "Stadt Baden-Baden",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BAR",
    "region": "BARnim",
    "district": "Landkreis Barnim",
    "county": "Brandenburg"
  },
  {
    "sign": "BB",
    "region": "BöBlingen",
    "district": "Landkreis Böblingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BBG",
    "region": "BernBurG",
    "district": "Salzlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "BC",
    "region": "BiberaCh",
    "district": "Landkreis Biberach",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BCH",
    "region": "BuCHen",
    "district": "Neckar-Odenwald-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BD",
    "region": "BundesDienst",
    "district": "Deutscher Bundestag, Bundesrat, Bundespräsidialamt, Bundesregierung, Bundesministerien, Bundesfinanzverwaltung und Bundesverfassungsgericht",
    "county": "bundesweit"
  },
  {
    "sign": "BE",
    "region": "BEckum",
    "district": "Kreis Warendorf",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BED",
    "region": "Brand-ErbisDorf",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "BER",
    "region": "BERnau",
    "district": "Landkreis Barnim",
    "county": "Brandenburg"
  },
  {
    "sign": "BF",
    "region": "BurgsteinFurt",
    "district": "Kreis Steinfurt",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BGD",
    "region": "BerchtesGaDen",
    "district": "Landkreis Berchtesgadener Land",
    "county": "Bayern"
  },
  {
    "sign": "BGL",
    "region": "BerchtesGadener Land",
    "district": "Landkreis Berchtesgadener Land",
    "county": "Bayern"
  },
  {
    "sign": "BH",
    "region": "BüHl",
    "district": "Ortenaukreis, Landkreis Rastatt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BI",
    "region": "BIelefeld",
    "district": "Stadt Bielefeld",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BID",
    "region": "BIeDenkopf",
    "district": "Landkreis Marburg-Biedenkopf",
    "county": "Hessen"
  },
  {
    "sign": "BIN",
    "region": "BINgen",
    "district": "Landkreis Mainz-Bingen",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "BIR",
    "region": "BIRkenfeld",
    "district": "Landkreis Birkenfeld",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "BIT",
    "region": "BITburg",
    "district": "Eifelkreis Bitburg-Prüm",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "BIW",
    "region": "BIschofsWerda",
    "district": "Landkreis Bautzen",
    "county": "Sachsen"
  },
  {
    "sign": "BK",
    "region": "BacKnang",
    "district": "Landkreis Backnang",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BKS",
    "region": "BernKaStel",
    "district": "Landkreis Bernkastel-Wittlich",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "BL",
    "region": "BaLingen",
    "district": "Zollernalbkreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BLB",
    "region": "BerLeBurg",
    "district": "Kreis Siegen-Wittgenstein",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BLK",
    "region": "BurgenLandKreis",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "BM",
    "region": "BergheiM",
    "district": "Rhein-Erft-Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BN",
    "region": "BonN",
    "district": "Stadt Bonn, Diplomaten",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BNA",
    "region": "BorNA",
    "district": "Landkreis Leipzig",
    "county": "Sachsen"
  },
  {
    "sign": "BO",
    "region": "BOchum",
    "district": "Stadt Bochum",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BÖ",
    "region": "BÖrde",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "BOG",
    "region": "BOGen",
    "district": "Landkreis Straubing-Bogen",
    "county": "Bayern"
  },
  {
    "sign": "BOH",
    "region": "BOcHolt",
    "district": "Kreis Borken",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BOR",
    "region": "BORken",
    "district": "Kreis Borken",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BOT",
    "region": "BOTtrop",
    "district": "Stadt Bottrop",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BP",
    "region": "BundesPolizei",
    "district": "Bundespolizei",
    "county": "bundesweit"
  },
  {
    "sign": "BRA",
    "region": "BRAke",
    "district": "Landkreis Wesermarsch",
    "county": "Niedersachsen"
  },
  {
    "sign": "BRB",
    "region": "BRandenBurg",
    "district": "Stadt Brandenburg an der Havel",
    "county": "Brandenburg"
  },
  {
    "sign": "BRG",
    "region": "BuRG",
    "district": "Landkreis Jerichower Land",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "BRK",
    "region": "BRücKenau",
    "district": "Landkreis Bad Kissingen",
    "county": "Bayern"
  },
  {
    "sign": "BRL",
    "region": "BRaunLage",
    "district": "Landkreis Goslar",
    "county": "Niedersachsen"
  },
  {
    "sign": "BRV",
    "region": "BRemerVörde",
    "district": "Landkreis Rotenburg (Wümme)",
    "county": "Niedersachsen"
  },
  {
    "sign": "BS",
    "region": "BraunSchweig",
    "district": "Stadt Braunschweig",
    "county": "Niedersachsen"
  },
  {
    "sign": "BSB",
    "region": "BerSenBrück",
    "district": "Landkreis Osnabrück",
    "county": "Niedersachsen"
  },
  {
    "sign": "BSK",
    "region": "BeeSKow",
    "district": "Landkreis Oder-Spree",
    "county": "Brandenburg"
  },
  {
    "sign": "BT",
    "region": "BayreuTh",
    "district": "Stadt Bayreuth, Landkreis Bayreuth",
    "county": "Bayern"
  },
  {
    "sign": "BTF",
    "region": "BitTerFeld",
    "district": "Landkreis Anhalt-Bitterfeld",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "BÜD",
    "region": "BÜDingen",
    "district": "Wetteraukreis",
    "county": "Hessen"
  },
  {
    "sign": "BUL",
    "region": "BUrgLengenfeld",
    "district": "Landkreis Amberg-Sulzbach, Landkreis Schwandorf",
    "county": "Bayern"
  },
  {
    "sign": "BÜR",
    "region": "BÜRen",
    "district": "Kreis Paderborn",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "BÜS",
    "region": "BÜSingen",
    "district": "Gemeinde Büsingen am Hochrhein (Gemeinde im Landkreis Konstanz)",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "BÜZ",
    "region": "BÜtZow",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "BZ",
    "region": "BautZen",
    "district": "Landkreis Bautzen",
    "county": "Sachsen"
  },
  {
    "sign": "C",
    "region": "Chemnitz",
    "district": "Stadt Chemnitz",
    "county": "Sachsen"
  },
  {
    "sign": "CA",
    "region": "CAlau",
    "district": "Landkreis Oberspreewald-Lausitz",
    "county": "Brandenburg"
  },
  {
    "sign": "CAS",
    "region": "CAStrop",
    "district": "Kreis Recklinghausen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "CB",
    "region": "CottBus",
    "district": "Stadt Cottbus",
    "county": "Brandenburg"
  },
  {
    "sign": "CE",
    "region": "CElle",
    "district": "Landkreis Celle",
    "county": "Niedersachsen"
  },
  {
    "sign": "CHA",
    "region": "CHAm",
    "district": "Landkreis Cham",
    "county": "Bayern"
  },
  {
    "sign": "CLP",
    "region": "CLopPenburg",
    "district": "Landkreis Cloppenburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "CLZ",
    "region": "CLausthal-Zellerfeld",
    "district": "Landkreis Goslar",
    "county": "Niedersachsen"
  },
  {
    "sign": "CO",
    "region": "COburg",
    "district": "Stadt Coburg und Landkreis Coburg",
    "county": "Bayern"
  },
  {
    "sign": "COC",
    "region": "COChem",
    "district": "Landkreis Cochem-Zell",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "COE",
    "region": "COEsfeld",
    "district": "Kreis Coesfeld",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "CR",
    "region": "CRailsheim",
    "district": "Landkreis Schwäbisch Hall",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "CUX",
    "region": "CUXhaven",
    "district": "Landkreis Cuxhaven",
    "county": "Niedersachsen"
  },
  {
    "sign": "CW",
    "region": "CalW",
    "district": "Landkreis Calw",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "D",
    "region": "Düsseldorf",
    "district": "Stadt Düsseldorf",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "DA",
    "region": "DArmstadt",
    "district": "Landkreis Darmstadt-Dieburg und Stadt Darmstadt",
    "county": "Hessen"
  },
  {
    "sign": "DAH",
    "region": "DAcHau",
    "district": "Landkreis Dachau",
    "county": "Bayern"
  },
  {
    "sign": "DAN",
    "region": "DANnenberg",
    "district": "Landkreis Lüchow-Dannenberg",
    "county": "Niedersachsen"
  },
  {
    "sign": "DAU",
    "region": "DAUn",
    "district": "Landkreis Vulkaneifel",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "DBR",
    "region": "DoBeRan",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "DD",
    "region": "DresDen",
    "district": "Stadt Dresden, Polizei Sachsen",
    "county": "Sachsen"
  },
  {
    "sign": "DE",
    "region": "DEssau",
    "district": "Stadt Dessau-Roßlau",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "DEG",
    "region": "DEGgendorf",
    "district": "Landkreis Deggendorf",
    "county": "Bayern"
  },
  {
    "sign": "DEL",
    "region": "DELmenhorst",
    "district": "Stadt Delmenhorst",
    "county": "Niedersachsen"
  },
  {
    "sign": "DGF",
    "region": "DinGolFing",
    "district": "Landkreis Dingolfing-Landau",
    "county": "Bayern"
  },
  {
    "sign": "DH",
    "region": "DiepHolz",
    "district": "Landkreis Diepholz",
    "county": "Niedersachsen"
  },
  {
    "sign": "DI",
    "region": "DIeburg",
    "district": "Landkreis Darmstadt-Dieburg",
    "county": "Hessen"
  },
  {
    "sign": "DIL",
    "region": "DILlenburg",
    "district": "Lahn-Dill-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "DIN",
    "region": "DINslaken",
    "district": "Kreis Wesel",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "DIZ",
    "region": "DIeZ",
    "district": "Rhein-Lahn-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "DKB",
    "region": "DinKelsBühl",
    "district": "Landkreis Ansbach",
    "county": "Bayern"
  },
  {
    "sign": "DL",
    "region": "DöbeLn",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "DLG",
    "region": "DilLinGen",
    "district": "Landkreis Dillingen an der Donau",
    "county": "Bayern"
  },
  {
    "sign": "DM",
    "region": "DemMin",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "DN",
    "region": "DüreN",
    "district": "Kreis Düren",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "DO",
    "region": "DOrtmund",
    "district": "Stadt Dortmund",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "DON",
    "region": "DONauwörth",
    "district": "Landkreis Donau-Ries",
    "county": "Bayern"
  },
  {
    "sign": "DU",
    "region": "DUisburg",
    "district": "Stadt Duisburg",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "DUD",
    "region": "DUDerstadt",
    "district": "Landkreis Göttingen",
    "county": "Niedersachsen"
  },
  {
    "sign": "DÜW",
    "region": "DÜrkheim an der Weinstraße",
    "district": "Landkreis Bad Dürkheim",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "DW",
    "region": "DippoldisWalde",
    "district": "Landkreis Sächsische Schweiz-Osterzgebirge",
    "county": "Sachsen"
  },
  {
    "sign": "DZ",
    "region": "DelitZsch",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "E",
    "region": "Essen",
    "district": "Stadt Essen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "EA",
    "region": "EisenAch",
    "district": "Stadt Eisenach",
    "county": "Thüringen"
  },
  {
    "sign": "EB",
    "region": "EilenBurg",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "EBE",
    "region": "EBErsberg",
    "district": "Landkreis Ebersberg",
    "county": "Bayern"
  },
  {
    "sign": "EBN",
    "region": "EBerN",
    "district": "Landkreis Haßberge",
    "county": "Bayern"
  },
  {
    "sign": "EBS",
    "region": "EBermannStadt",
    "district": "Landkreis Forchheim, Landkreis Kulmbach, Landkreis Bayreuth",
    "county": "Bayern"
  },
  {
    "sign": "ECK",
    "region": "ECKernförde",
    "district": "Kreis Rendsburg-Eckernförde",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "ED",
    "region": "ErDing",
    "district": "Landkreis Erding",
    "county": "Bayern"
  },
  {
    "sign": "EE",
    "region": "Elbe, Elster",
    "district": "Landkreis Elbe-Elster",
    "county": "Brandenburg"
  },
  {
    "sign": "EF",
    "region": "ErFurt",
    "district": "Stadt Erfurt",
    "county": "Thüringen"
  },
  {
    "sign": "EG",
    "region": "EGgenfelden",
    "district": "Landkreis Rottal-Inn",
    "county": "Bayern"
  },
  {
    "sign": "EH",
    "region": "EisenHüttenstadt",
    "district": "Landkreis Oder-Spree",
    "county": "Brandenburg"
  },
  {
    "sign": "EI",
    "region": "EIchstätt",
    "district": "Landkreis Eichstätt",
    "county": "Bayern"
  },
  {
    "sign": "EIC",
    "region": "EIChsfeld",
    "district": "Landkreis Eichsfeld",
    "county": "Thüringen"
  },
  {
    "sign": "EIL",
    "region": "EIsLeben",
    "district": "Landkreis Mansfeld-Südharz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "EIN",
    "region": "EINbeck",
    "district": "Landkreis Northeim",
    "county": "Niedersachsen"
  },
  {
    "sign": "EIS",
    "region": "EISenberg",
    "district": "Saale-Holzland-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "EL",
    "region": "EmsLand",
    "district": "Landkreis Emsland",
    "county": "Niedersachsen"
  },
  {
    "sign": "EM",
    "region": "EMmendingen",
    "district": "Landkreis Emmendingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "EMD",
    "region": "EMDen",
    "district": "Stadt Emden",
    "county": "Niedersachsen"
  },
  {
    "sign": "EMS",
    "region": "EMS",
    "district": "Rhein-Lahn-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "EN",
    "region": "ENnepe",
    "district": "Ennepe-Ruhr-Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "ER",
    "region": "ERlangen",
    "district": "Stadt Erlangen",
    "county": "Bayern"
  },
  {
    "sign": "ERB",
    "region": "ERBach",
    "district": "Odenwaldkreis",
    "county": "Hessen"
  },
  {
    "sign": "ERH",
    "region": "ERlangen, Höchstadt",
    "district": "Landkreis Erlangen-Höchstadt",
    "county": "Bayern"
  },
  {
    "sign": "ERK",
    "region": "ERKelenz",
    "district": "Kreis Heinsberg",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "ERZ",
    "region": "ERZgebirge",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "ES",
    "region": "ESslingen",
    "district": "Landkreis Esslingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "ESB",
    "region": "ESchenBach",
    "district": "Landkreis Neustadt an der Waldnaab, Landkreis Amberg-Sulzbach, Landkreis Bayreuth, Landkreis Nürnberger Land",
    "county": "Bayern"
  },
  {
    "sign": "ESW",
    "region": "ESchWege",
    "district": "Werra-Meißner-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "EU",
    "region": "EUskirchen",
    "district": "Kreis Euskirchen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "EW",
    "region": "EbersWalde",
    "district": "Landkreis Barnim",
    "county": "Brandenburg"
  },
  {
    "sign": "F",
    "region": "Frankfurt",
    "district": "Stadt Frankfurt am Main",
    "county": "Hessen"
  },
  {
    "sign": "FB",
    "region": "FriedBerg",
    "district": "Wetteraukreis",
    "county": "Hessen"
  },
  {
    "sign": "FD",
    "region": "FulDa",
    "district": "Landkreis Fulda",
    "county": "Hessen"
  },
  {
    "sign": "FDB",
    "region": "FrieDBerg",
    "district": "Landkreis Aichach-Friedberg",
    "county": "Bayern"
  },
  {
    "sign": "FDS",
    "region": "FreuDenStadt",
    "district": "Landkreis Freudenstadt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "FEU",
    "region": "FEUchtwangen",
    "district": "Landkreis Ansbach",
    "county": "Bayern"
  },
  {
    "sign": "FF",
    "region": "FrankFurt",
    "district": "Stadt Frankfurt (Oder)",
    "county": "Brandenburg"
  },
  {
    "sign": "FFB",
    "region": "FürstenFeldBruck",
    "district": "Landkreis Fürstenfeldbruck",
    "county": "Bayern"
  },
  {
    "sign": "FG",
    "region": "FreiberG",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "FI",
    "region": "FInsterwalde",
    "district": "Landkreis Elbe-Elster",
    "county": "Brandenburg"
  },
  {
    "sign": "FKB",
    "region": "FranKenBerg",
    "district": "Landkreis Waldeck-Frankenberg",
    "county": "Hessen"
  },
  {
    "sign": "FL",
    "region": "FLensburg",
    "district": "Stadt Flensburg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "FLÖ",
    "region": "FLÖha",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "FN",
    "region": "FriedrichshafeN",
    "district": "Bodenseekreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "FO",
    "region": "FOrchheim",
    "district": "Landkreis Forchheim",
    "county": "Bayern"
  },
  {
    "sign": "FOR",
    "region": "FORst",
    "district": "Landkreis Spree-Neiße",
    "county": "Brandenburg"
  },
  {
    "sign": "FR",
    "region": "FReiburg",
    "district": "Landkreis Breisgau-Hochschwarzwald, Stadt Freiburg im Breisgau",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "FRG",
    "region": "FReyung, Grafenau",
    "district": "Landkreis Freyung-Grafenau",
    "county": "Bayern"
  },
  {
    "sign": "FRI",
    "region": "FRIesland",
    "district": "Landkreis Friesland",
    "county": "Niedersachsen"
  },
  {
    "sign": "FRW",
    "region": "FReienWalde",
    "district": "Landkreis Märkisch-Oderland",
    "county": "Brandenburg"
  },
  {
    "sign": "FS",
    "region": "FreiSing",
    "district": "Landkreis Freising",
    "county": "Bayern"
  },
  {
    "sign": "FT",
    "region": "FrankenThal",
    "district": "Stadt Frankenthal (Pfalz)",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "FTL",
    "region": "FreiTaL",
    "district": "Landkreis Sächsische Schweiz-Osterzgebirge",
    "county": "Sachsen"
  },
  {
    "sign": "FÜ",
    "region": "FÜrth",
    "district": "Landkreis Fürth, Stadt Fürth",
    "county": "Bayern"
  },
  {
    "sign": "FÜS",
    "region": "FÜSsen",
    "district": "Landkreis Ostallgäu",
    "county": "Bayern"
  },
  {
    "sign": "FW",
    "region": "FürstenWalde",
    "district": "Landkreis Oder-Spree",
    "county": "Brandenburg"
  },
  {
    "sign": "FZ",
    "region": "FritZlar",
    "district": "Schwalm-Eder-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "G",
    "region": "Gera",
    "district": "Stadt Gera",
    "county": "Thüringen"
  },
  {
    "sign": "GA",
    "region": "GArdelegen",
    "district": "Altmarkkreis Salzwedel",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "GAN",
    "region": "GANdersheim",
    "district": "Landkreis Northeim",
    "county": "Niedersachsen"
  },
  {
    "sign": "GAP",
    "region": "GArmisch-Partenkirchen",
    "district": "Landkreis Garmisch-Partenkirchen",
    "county": "Bayern"
  },
  {
    "sign": "GC",
    "region": "GlauChau",
    "district": "Landkreis Zwickau",
    "county": "Sachsen"
  },
  {
    "sign": "GD",
    "region": "GmünD",
    "district": "Ostalbkreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "GDB",
    "region": "GaDeBusch",
    "district": "Landkreis Nordwestmecklenburg",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "GE",
    "region": "GElsenkirchen",
    "district": "Stadt Gelsenkirchen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GEL",
    "region": "GELdern",
    "district": "Kreis Kleve",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GEO",
    "region": "GErOlzhofen",
    "district": "Landkreis Schweinfurt, Landkreis Haßberge",
    "county": "Bayern"
  },
  {
    "sign": "GER",
    "region": "GERmersheim",
    "district": "Landkreis Germersheim",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "GF",
    "region": "GiFhorn",
    "district": "Landkreis Gifhorn",
    "county": "Niedersachsen"
  },
  {
    "sign": "GG",
    "region": "Groß-Gerau",
    "district": "Kreis Groß-Gerau",
    "county": "Hessen"
  },
  {
    "sign": "GHA",
    "region": "GeitHAin",
    "district": "Landkreis Leipzig",
    "county": "Sachsen"
  },
  {
    "sign": "GHC",
    "region": "GräfenHainiChen",
    "district": "Landkreis Wittenberg",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "GI",
    "region": "GIeßen",
    "district": "Landkreis Gießen",
    "county": "Hessen"
  },
  {
    "sign": "GK",
    "region": "GeilenKirchen",
    "district": "Kreis Heinsberg",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GL",
    "region": "GLadbach",
    "district": "Rheinisch-Bergischer Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GLA",
    "region": "GLAdbeck",
    "district": "Kreis Recklinghausen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GM",
    "region": "GumMersbach",
    "district": "Oberbergischer Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GMN",
    "region": "GrimMeN",
    "district": "Landkreis Vorpommern-Rügen",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "GN",
    "region": "GelnhauseN",
    "district": "Main-Kinzig-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "GNT",
    "region": "GeNThin",
    "district": "Landkreis Jerichower Land",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "GÖ",
    "region": "GÖttingen",
    "district": "Landkreis Göttingen, Stadt Göttingen",
    "county": "Niedersachsen"
  },
  {
    "sign": "GOA",
    "region": "GOAr",
    "district": "Rhein-Hunsrück-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "GOH",
    "region": "GOarsHausen",
    "district": "Rhein-Lahn-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "GP",
    "region": "GöpPingen",
    "district": "Landkreis Göppingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "GR",
    "region": "GöRlitz",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "GRA",
    "region": "GRAfenau",
    "district": "Landkreis Freyung-Grafenau",
    "county": "Bayern"
  },
  {
    "sign": "GRH",
    "region": "GRoßenHain",
    "district": "Landkreis Meißen",
    "county": "Sachsen"
  },
  {
    "sign": "GRI",
    "region": "GRIesbach",
    "district": "Landkreis Rottal-Inn",
    "county": "Bayern"
  },
  {
    "sign": "GRM",
    "region": "GRimMa",
    "district": "Landkreis Leipzig",
    "county": "Sachsen"
  },
  {
    "sign": "GRZ",
    "region": "GReiZ",
    "district": "Landkreis Greiz",
    "county": "Thüringen"
  },
  {
    "sign": "GS",
    "region": "GoSlar",
    "district": "Landkreis Goslar",
    "county": "Niedersachsen"
  },
  {
    "sign": "GT",
    "region": "GüTersloh",
    "district": "Kreis Gütersloh",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GTH",
    "region": "GoTHa",
    "district": "Landkreis Gotha",
    "county": "Thüringen"
  },
  {
    "sign": "GÜ",
    "region": "GÜstrow",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "GUB",
    "region": "GUBen",
    "district": "Landkreis Spree-Neiße",
    "county": "Brandenburg"
  },
  {
    "sign": "GUN",
    "region": "GUNzenhausen",
    "district": "Landkreis Weißenburg-Gunzenhausen",
    "county": "Bayern"
  },
  {
    "sign": "GV",
    "region": "GreVenbroich",
    "district": "Rhein-Kreis Neuss",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "GVM",
    "region": "GreVesMühlen",
    "district": "Landkreis Nordwestmecklenburg",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "GW",
    "region": "GreifsWald",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "GZ",
    "region": "GünZburg",
    "district": "Landkreis Günzburg",
    "county": "Bayern"
  },
  {
    "sign": "H",
    "region": "Hannover",
    "district": "Region Hannover",
    "county": "Niedersachsen"
  },
  {
    "sign": "HA",
    "region": "HAgen",
    "district": "Stadt Hagen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HAB",
    "region": "HAmmelBurg",
    "district": "Landkreis Bad Kissingen",
    "county": "Bayern"
  },
  {
    "sign": "HAL",
    "region": "HALle",
    "district": "Stadt Halle (Saale)",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HAM",
    "region": "HAMm",
    "district": "Stadt Hamm",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HAS",
    "region": "HASsfurt",
    "district": "Landkreis Haßberge",
    "county": "Bayern"
  },
  {
    "sign": "HB",
    "region": "Hansestadt Bremen",
    "district": "Stadt Bremen, Senat und Bürgerschaft, Stadt Bremerhaven (A–Z 1000–9999)",
    "county": "Bremen"
  },
  {
    "sign": "HBN",
    "region": "HildBurghauseN",
    "district": "Landkreis Hildburghausen",
    "county": "Thüringen"
  },
  {
    "sign": "HBS",
    "region": "HalBerStadt",
    "district": "Landkreis Harz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HC",
    "region": "HainiChen",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "HCH",
    "region": "HeCHingen",
    "district": "Zollernalbkreis, Landkreis Freudenstadt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "HD",
    "region": "HeiDelberg",
    "district": "Stadt Heidelberg, Rhein-Neckar-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "HDH",
    "region": "HeiDenHeim",
    "district": "Landkreis Heidenheim",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "HDL",
    "region": "HalDensLeben",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HE",
    "region": "HElmstedt",
    "district": "Landkreis Helmstedt",
    "county": "Niedersachsen"
  },
  {
    "sign": "HEB",
    "region": "HErsBruck",
    "district": "Landkreis Nürnberger Land",
    "county": "Bayern"
  },
  {
    "sign": "HEF",
    "region": "HErsFeld",
    "district": "Landkreis Hersfeld-Rotenburg",
    "county": "Hessen"
  },
  {
    "sign": "HEI",
    "region": "HEIde",
    "district": "Kreis Dithmarschen",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "HER",
    "region": "HERne",
    "district": "Stadt Herne",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HET",
    "region": "HETtstedt",
    "district": "Landkreis Mansfeld-Südharz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HF",
    "region": "HerFord",
    "district": "Kreis Herford",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HG",
    "region": "HomburG",
    "district": "Hochtaunuskreis",
    "county": "Hessen"
  },
  {
    "sign": "HGN",
    "region": "HaGeNow",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "HGW",
    "region": "Hansestadt GreifsWald",
    "district": "Stadt Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "HH",
    "region": "Hansestadt Hamburg",
    "district": "Freie und Hansestadt Hamburg",
    "county": "Hamburg"
  },
  {
    "sign": "HHM",
    "region": "HoHenMölsen",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HI",
    "region": "HIldesheim",
    "district": "Landkreis Hildesheim",
    "county": "Niedersachsen"
  },
  {
    "sign": "HIG",
    "region": "HeilIGenstadt",
    "district": "Landkreis Eichsfeld",
    "county": "Thüringen"
  },
  {
    "sign": "HIP",
    "region": "HIlPoltstein",
    "district": "Landkreis Roth",
    "county": "Bayern"
  },
  {
    "sign": "HK",
    "region": "HeideKreis",
    "district": "Landkreis Heidekreis",
    "county": "Niedersachsen"
  },
  {
    "sign": "HL",
    "region": "Hansestadt Lübeck",
    "district": "Stadt Lübeck",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "HM",
    "region": "HaMeln",
    "district": "Landkreis Hameln-Pyrmont",
    "county": "Niedersachsen"
  },
  {
    "sign": "HMÜ",
    "region": "Hann. MÜnden",
    "district": "Landkreis Göttingen",
    "county": "Niedersachsen"
  },
  {
    "sign": "HN",
    "region": "HeilbronN",
    "district": "Stadt Heilbronn, Landkreis Heilbronn",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "HO",
    "region": "HOf",
    "district": "Stadt Hof, Landkreis Hof",
    "county": "Bayern"
  },
  {
    "sign": "HOG",
    "region": "HOfGeismar",
    "district": "Landkreis Kassel",
    "county": "Hessen"
  },
  {
    "sign": "HOH",
    "region": "HOfHeim",
    "district": "Landkreis Haßberge",
    "county": "Bayern"
  },
  {
    "sign": "HOL",
    "region": "HOLzminden",
    "district": "Landkreis Holzminden",
    "county": "Niedersachsen"
  },
  {
    "sign": "HOM",
    "region": "HOMburg",
    "district": "Saarpfalz-Kreis",
    "county": "Saarland"
  },
  {
    "sign": "HOR",
    "region": "HORb",
    "district": "Landkreis Freudenstadt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "HÖS",
    "region": "HÖchStadt",
    "district": "Landkreis Erlangen-Höchstadt",
    "county": "Bayern"
  },
  {
    "sign": "HOT",
    "region": "HOhensTein",
    "district": "Landkreis Zwickau",
    "county": "Sachsen"
  },
  {
    "sign": "HP",
    "region": "HepPenheim",
    "district": "Kreis Bergstraße",
    "county": "Hessen"
  },
  {
    "sign": "HR",
    "region": "HombeRg",
    "district": "Schwalm-Eder-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "HRO",
    "region": "Hansestadt ROstock",
    "district": "Stadt Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "HS",
    "region": "HeinSberg",
    "district": "Kreis Heinsberg",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HSK",
    "region": "HochSauerlandKreis",
    "district": "Hochsauerlandkreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HST",
    "region": "Hansestadt STralsund",
    "district": "Stadt Stralsund",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "HU",
    "region": "HanaU",
    "district": "Stadt Hanau",
    "county": "Hessen"
  },
  {
    "sign": "HV",
    "region": "HaVelberg",
    "district": "Landkreis Stendal",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "HVL",
    "region": "HaVelLand",
    "district": "Landkreis Havelland",
    "county": "Brandenburg"
  },
  {
    "sign": "HWI",
    "region": "Hansestadt WIsmar",
    "district": "Stadt Wismar",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "HX",
    "region": "HöXter",
    "district": "Kreis Höxter",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "HY",
    "region": "HoYerswerda",
    "district": "Landkreis Bautzen",
    "county": "Sachsen"
  },
  {
    "sign": "HZ",
    "region": "HarZ",
    "district": "Landkreis Harz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "IGB",
    "region": "InGBert",
    "district": "Stadt St. Ingbert (Mittelstadt im Saarpfalz-Kreis)",
    "county": "Saarland"
  },
  {
    "sign": "IK",
    "region": "Ilm-Kreis",
    "district": "Ilm-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "IL",
    "region": "ILmenau",
    "district": "Ilm-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "ILL",
    "region": "ILLertissen",
    "district": "Landkreis Neu-Ulm",
    "county": "Bayern"
  },
  {
    "sign": "IN",
    "region": "INgolstadt",
    "district": "Stadt Ingolstadt",
    "county": "Bayern"
  },
  {
    "sign": "IZ",
    "region": "ItZehoe",
    "district": "Kreis Steinburg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "J",
    "region": "Jena",
    "district": "Stadt Jena",
    "county": "Thüringen"
  },
  {
    "sign": "JE",
    "region": "JEssen",
    "district": "Landkreis Wittenberg",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "JL",
    "region": "Jerichower Land",
    "district": "Landkreis Jerichower Land",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "JÜL",
    "region": "JÜLich",
    "district": "Kreis Düren",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "K",
    "region": "Köln",
    "district": "Stadt Köln",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "KA",
    "region": "KArlsruhe",
    "district": "Landkreis Karlsruhe, Stadt Karlsruhe",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "KB",
    "region": "KorBach",
    "district": "Landkreis Waldeck-Frankenberg",
    "county": "Hessen"
  },
  {
    "sign": "KC",
    "region": "KronaCh",
    "district": "Landkreis Kronach",
    "county": "Bayern"
  },
  {
    "sign": "KE",
    "region": "KEmpten",
    "district": "Stadt Kempten (Allgäu)",
    "county": "Bayern"
  },
  {
    "sign": "KEH",
    "region": "KElHeim",
    "district": "Landkreis Kelheim",
    "county": "Bayern"
  },
  {
    "sign": "KEL",
    "region": "KEhL",
    "district": "Ortenaukreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "KEM",
    "region": "KEMnath",
    "district": "Landkreis Tirschenreuth, Landkreis Bayreuth",
    "county": "Bayern"
  },
  {
    "sign": "KF",
    "region": "KauFbeuren",
    "district": "Stadt Kaufbeuren",
    "county": "Bayern"
  },
  {
    "sign": "KG",
    "region": "KissinGen",
    "district": "Landkreis Bad Kissingen",
    "county": "Bayern"
  },
  {
    "sign": "KH",
    "region": "KreuznacH",
    "district": "Landkreis Bad Kreuznach",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "KI",
    "region": "KIel",
    "district": "Stadt Kiel",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "KIB",
    "region": "KIrchheimBolanden",
    "district": "Donnersbergkreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "KK",
    "region": "Kempen, Krefeld",
    "district": "Kreis Viersen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "KL",
    "region": "KaisersLautern",
    "district": "Stadt Kaiserslautern und Landkreis Kaiserslautern",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "KLE",
    "region": "KLEve",
    "district": "Kreis Kleve",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "KLZ",
    "region": "KLötZe",
    "district": "Altmarkkreis Salzwedel",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "KM",
    "region": "KaMenz",
    "district": "Landkreis Bautzen",
    "county": "Sachsen"
  },
  {
    "sign": "KN",
    "region": "KoNstanz",
    "district": "Landkreis Konstanz",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "KO",
    "region": "KOblenz",
    "district": "Stadt Koblenz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "KÖN",
    "region": "KÖNigshofen",
    "district": "Landkreis Rhön-Grabfeld",
    "county": "Bayern"
  },
  {
    "sign": "KÖT",
    "region": "KÖThen",
    "district": "Landkreis Anhalt-Bitterfeld",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "KÖZ",
    "region": "KÖtZting",
    "district": "Landkreis Cham",
    "county": "Bayern"
  },
  {
    "sign": "KR",
    "region": "KRefeld",
    "district": "Stadt Krefeld",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "KRU",
    "region": "KRUmbach",
    "district": "Landkreis Günzburg",
    "county": "Bayern"
  },
  {
    "sign": "KS",
    "region": "KasSel",
    "district": "Landkreis Kassel und Stadt Kassel",
    "county": "Hessen"
  },
  {
    "sign": "KT",
    "region": "KiTzingen",
    "district": "Landkreis Kitzingen",
    "county": "Bayern"
  },
  {
    "sign": "KU",
    "region": "KUlmbach",
    "district": "Landkreis Kulmbach",
    "county": "Bayern"
  },
  {
    "sign": "KÜN",
    "region": "KÜNzelsau",
    "district": "Hohenlohekreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "KUS",
    "region": "KUSel",
    "district": "Landkreis Kusel",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "KW",
    "region": "Königs Wusterhausen",
    "district": "Landkreis Dahme-Spreewald",
    "county": "Brandenburg"
  },
  {
    "sign": "KY",
    "region": "KYritz",
    "district": "Landkreis Ostprignitz-Ruppin",
    "county": "Brandenburg"
  },
  {
    "sign": "KYF",
    "region": "KYFfhäuser",
    "district": "Kyffhäuserkreis",
    "county": "Thüringen"
  },
  {
    "sign": "L",
    "region": "Leipzig",
    "district": "Sachsen",
    "county": "Sachsen"
  },
  {
    "sign": "LA",
    "region": "LAndshut",
    "district": "Stadt Landshut",
    "county": "Bayern"
  },
  {
    "sign": "LAN",
    "region": "LANdau",
    "district": "Landkreis Dingolfing-Landau",
    "county": "Bayern"
  },
  {
    "sign": "LAU",
    "region": "LAUf",
    "district": "Landkreis Nürnberger Land",
    "county": "Bayern"
  },
  {
    "sign": "LB",
    "region": "LudwigsBurg",
    "district": "Landkreis Ludwigsburg",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "LBS",
    "region": "LoBenStein",
    "district": "Saale-Orla-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "LBZ",
    "region": "LüBZ",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "LC",
    "region": "LuCkau",
    "district": "Landkreis Dahme-Spreewald",
    "county": "Brandenburg"
  },
  {
    "sign": "LD",
    "region": "LanDau",
    "district": "Stadt Landau in der Pfalz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "LDK",
    "region": "Lahn-Dill-Kreis",
    "district": "Lahn-Dill-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "LDS",
    "region": "Landkreis Dahme-Spreewald",
    "district": "Landkreis Dahme-Spreewald",
    "county": "Brandenburg"
  },
  {
    "sign": "LEO",
    "region": "LEOnberg",
    "district": "Landkreis Böblingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "LER",
    "region": "LEeR",
    "district": "Landkreis Leer",
    "county": "Niedersachsen"
  },
  {
    "sign": "LEV",
    "region": "LEVerkusen",
    "district": "Stadt Leverkusen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "LF",
    "region": "LauFen",
    "district": "Landkreis Berchtesgadener Land",
    "county": "Bayern"
  },
  {
    "sign": "LG",
    "region": "LüneburG",
    "district": "Landkreis Lüneburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "LH",
    "region": "LüdingHausen",
    "district": "Kreis Coesfeld, Kreis Unna",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "LI",
    "region": "LIndau",
    "district": "Landkreis Lindau (Bodensee)",
    "county": "Bayern"
  },
  {
    "sign": "LIB",
    "region": "LIeBenwerda",
    "district": "Landkreis Elbe-Elster",
    "county": "Brandenburg"
  },
  {
    "sign": "LIF",
    "region": "LIchtenFels",
    "district": "Landkreis Lichtenfels",
    "county": "Bayern"
  },
  {
    "sign": "LIP",
    "region": "LIPpe",
    "district": "Kreis Lippe",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "LL",
    "region": "Landsberg am Lech",
    "district": "Landkreis Landsberg am Lech",
    "county": "Bayern"
  },
  {
    "sign": "LM",
    "region": "LiMburg",
    "district": "Landkreis Limburg-Weilburg",
    "county": "Hessen"
  },
  {
    "sign": "LN",
    "region": "LübbeN",
    "district": "Landkreis Dahme-Spreewald",
    "county": "Brandenburg"
  },
  {
    "sign": "LÖ",
    "region": "LÖrrach",
    "district": "Landkreis Lörrach",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "LÖB",
    "region": "LÖBau",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "LOS",
    "region": "Landkreis Oder-Spre",
    "district": "Landkreis Oder-Spree",
    "county": "Brandenburg"
  },
  {
    "sign": "LP",
    "region": "LiPpstadt",
    "district": "Kreis Soest",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "LR",
    "region": "LahR",
    "district": "Ortenaukreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "LRO",
    "region": "Landkreis ROstock",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "LSZ",
    "region": "LangenSalZa",
    "district": "Unstrut-Hainich-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "LU",
    "region": "LUdwigshafen",
    "district": "Stadt Ludwigshafen am Rhein",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "LÜN",
    "region": "LÜNen",
    "district": "Kreis Unna",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "LUP",
    "region": "LUdwigslust, Parchim",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "LWL",
    "region": "LudWigsLust",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "M",
    "region": "München",
    "district": "München",
    "county": "Bayern"
  },
  {
    "sign": "MA",
    "region": "MAnnheim",
    "district": "Stadt Mannheim",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "MAB",
    "region": "MArienBerg",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "MAI",
    "region": "MAInburg",
    "district": "Landkreis Kelheim",
    "county": "Bayern"
  },
  {
    "sign": "MAK",
    "region": "MArKtredwitz",
    "district": "Landkreis Wunsiedel im Fichtelgebirge",
    "county": "Bayern"
  },
  {
    "sign": "MAL",
    "region": "MALlersdorf",
    "district": "Landkreis Landshut",
    "county": "Bayern"
  },
  {
    "sign": "MB",
    "region": "MiesBach",
    "district": "Landkreis Miesbach",
    "county": "Bayern"
  },
  {
    "sign": "MC",
    "region": "MalChin",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "MD",
    "region": "MagDeburg",
    "district": "Stadt Magdeburg",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "ME",
    "region": "MEttmann",
    "district": "Kreis Mettmann",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MED",
    "region": "MElDorf",
    "district": "Kreis Dithmarschen",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "MEG",
    "region": "MElsunGen",
    "district": "Schwalm-Eder-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "MEI",
    "region": "MEIßen",
    "district": "Landkreis Meißen",
    "county": "Sachsen"
  },
  {
    "sign": "MEK",
    "region": "Mittlerer ErzgebirgsKreis",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "MEL",
    "region": "MELle",
    "district": "Landkreis Osnabrück",
    "county": "Niedersachsen"
  },
  {
    "sign": "MER",
    "region": "MERseburg",
    "district": "Saalekreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "MET",
    "region": "MEllrichstadT",
    "district": "Landkreis Rhön-Grabfeld",
    "county": "Bayern"
  },
  {
    "sign": "MG",
    "region": "MönchenGladbach",
    "district": "Stadt Mönchengladbach",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MGH",
    "region": "MerGentHeim",
    "district": "Main-Tauber-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "MGN",
    "region": "MeininGeN",
    "district": "Landkreis Schmalkalden-Meiningen",
    "county": "Thüringen"
  },
  {
    "sign": "MH",
    "region": "MülHeim",
    "district": "Stadt Mülheim an der Ruhr",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MHL",
    "region": "MüHLhausen",
    "district": "Unstrut-Hainich-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "MI",
    "region": "MInden",
    "district": "Kreis Minden-Lübbecke",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MIL",
    "region": "MILtenberg",
    "district": "Landkreis Miltenberg",
    "county": "Bayern"
  },
  {
    "sign": "MK",
    "region": "Märkischer Kreis",
    "district": "Märkischer Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MKK",
    "region": "Main-Kinzig-Kreis",
    "district": "Main-Kinzig-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "ML",
    "region": "Mansfelder Land",
    "district": "Landkreis Mansfeld-Südharz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "MM",
    "region": "MemMingen",
    "district": "Stadt Memmingen",
    "county": "Bayern"
  },
  {
    "sign": "MN",
    "region": "MiNdelheim",
    "district": "Landkreis Unterallgäu",
    "county": "Bayern"
  },
  {
    "sign": "MO",
    "region": "MOers",
    "district": "Kreis Wesel",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MOD",
    "region": "MarktOberDorf",
    "district": "Landkreis Ostallgäu",
    "county": "Bayern"
  },
  {
    "sign": "MOL",
    "region": "Märkisch-OderLand",
    "district": "Landkreis Märkisch-Oderland",
    "county": "Brandenburg"
  },
  {
    "sign": "MON",
    "region": "MONschau",
    "district": "Städteregion Aachen, Kreis Düren",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MOS",
    "region": "MONschau",
    "district": "Neckar-Odenwald-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "MQ",
    "region": "Merseburg, Querfurt",
    "district": "Saalekreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "MR",
    "region": "MaRburg",
    "district": "Landkreis Marburg-Biedenkopf",
    "county": "Hessen"
  },
  {
    "sign": "MS",
    "region": "MünSter",
    "district": "Stadt Münster",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "MSE",
    "region": "Mecklenburgische SEenplatte",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "MSH",
    "region": "Mansfeld, SüdHarz",
    "district": "Landkreis Mansfeld-Südharz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "MSP",
    "region": "Main, SPessart",
    "district": "Landkreis Main-Spessart",
    "county": "Bayern"
  },
  {
    "sign": "MST",
    "region": "Mecklenburg-STrelitz",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "MTK",
    "region": "Main-Taunus-Kreis",
    "district": "Main-Taunus-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "MTL",
    "region": "MuldenTaL",
    "district": "Landkreis Leipzig",
    "county": "Sachsen"
  },
  {
    "sign": "MÜ",
    "region": "MÜhldorf",
    "district": "Landkreis Mühldorf am Inn",
    "county": "Bayern"
  },
  {
    "sign": "MÜB",
    "region": "MÜnchBerg",
    "district": "Landkreis Hof, Landkreis Bayreuth",
    "county": "Bayern"
  },
  {
    "sign": "MÜR",
    "region": "MÜRitz",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "MW",
    "region": "MittWeida",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "MY",
    "region": "MaYen",
    "district": "Landkreis Mayen-Koblenz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "MYK",
    "region": "MaYen, Koblenz",
    "district": "Landkreis Mayen-Koblenz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "MZ",
    "region": "MainZ",
    "district": "Landkreis Mainz-Bingen, Stadt Mainz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "MZG",
    "region": "MerZiG",
    "district": "Landkreis Merzig-Wadern",
    "county": "Saarland"
  },
  {
    "sign": "N",
    "region": "Nürnberg",
    "district": "Nürnberg",
    "county": "Bayern"
  },
  {
    "sign": "NAB",
    "region": "NABburg",
    "district": "Landkreis Schwandorf, Landkreis Amberg-Sulzbach",
    "county": "Bayern"
  },
  {
    "sign": "NAI",
    "region": "NAIla",
    "district": "Landkreis Hof",
    "county": "Bayern"
  },
  {
    "sign": "NAU",
    "region": "NAUen",
    "district": "Landkreis Havelland",
    "county": "Brandenburg"
  },
  {
    "sign": "NB",
    "region": "NeuBrandenburg",
    "district": "Stadt Neubrandenburg",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "ND",
    "region": "Neuburg an der Donau",
    "district": "Landkreis Neuburg-Schrobenhausen",
    "county": "Bayern"
  },
  {
    "sign": "NDH",
    "region": "NorDHausen",
    "district": "Landkreis Nordhausen",
    "county": "Thüringen"
  },
  {
    "sign": "NE",
    "region": "NEuss",
    "district": "Rhein-Kreis Neuss",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "NEA",
    "region": "NEustadt an der Aisch",
    "district": "Landkreis Neustadt an der Aisch-Bad Windsheim",
    "county": "Bayern"
  },
  {
    "sign": "NEB",
    "region": "NEBra",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "NEC",
    "region": "NEustadt bei Coburg",
    "district": "Stadt Coburg und Landkreis Coburg",
    "county": "Bayern"
  },
  {
    "sign": "NEN",
    "region": "NEuNburg",
    "district": "Landkreis Schwandorf",
    "county": "Bayern"
  },
  {
    "sign": "NES",
    "region": "NEustadt an der Saale",
    "district": "Landkreis Rhön-Grabfeld",
    "county": "Bayern"
  },
  {
    "sign": "NEW",
    "region": "NEustadt an der Waldnaab",
    "district": "Landkreis Neustadt an der Waldnaab",
    "county": "Bayern"
  },
  {
    "sign": "NF",
    "region": "NordFriesland",
    "district": "Kreis Nordfriesland",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "NH",
    "region": "NeuHaus",
    "district": "Landkreis Sonneberg",
    "county": "Thüringen"
  },
  {
    "sign": "NI",
    "region": "NIenburg",
    "district": "Landkreis Nienburg/Weser",
    "county": "Niedersachsen"
  },
  {
    "sign": "NK",
    "region": "NeunKirchen",
    "district": "Landkreis Neunkirchen",
    "county": "Saarland"
  },
  {
    "sign": "NM",
    "region": "NeuMarkt",
    "district": "Landkreis Neumarkt in der Oberpfalz",
    "county": "Bayern"
  },
  {
    "sign": "NMB",
    "region": "NauMBurg",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "NMS",
    "region": "NeuMünSter",
    "district": "Stadt Neumünster",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "NÖ",
    "region": "NÖrdlingen",
    "district": "Landkreis Donau-Ries",
    "county": "Bayern"
  },
  {
    "sign": "NOH",
    "region": "NOrdHorn",
    "district": "Landkreis Grafschaft Bentheim",
    "county": "Niedersachsen"
  },
  {
    "sign": "NOL",
    "region": "Niederschlesische OberLausitz",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "NOM",
    "region": "NOrtheiM",
    "district": "Landkreis Northeim",
    "county": "Niedersachsen"
  },
  {
    "sign": "NOR",
    "region": "NORden",
    "district": "Landkreis Aurich",
    "county": "Niedersachsen"
  },
  {
    "sign": "NP",
    "region": "NeurupPin",
    "district": "Landkreis Ostprignitz-Ruppin",
    "county": "Brandenburg"
  },
  {
    "sign": "NR",
    "region": "Neuwied am Rhein",
    "district": "Landkreis Neuwied",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "NT",
    "region": "NürTingen",
    "district": "Landkreis Esslingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "NU",
    "region": "Neu-Ulm",
    "district": "Landkreis Neu-Ulm",
    "county": "Bayern"
  },
  {
    "sign": "NVP",
    "region": "NordVorPommern",
    "district": "Landkreis Vorpommern-Rügen",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "NW",
    "region": "Neustadt an der Weinstraße",
    "district": "Stadt Neustadt an der Weinstraße",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "NWM",
    "region": "NordWestMecklenburg",
    "district": "Landkreis Nordwestmecklenburg",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "NY",
    "region": "NieskY",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "NZ",
    "region": "NeustrelitZ",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "OA",
    "region": "OberAllgäu",
    "district": "Landkreis Oberallgäu,",
    "county": "Bayern"
  },
  {
    "sign": "OAL",
    "region": "OstALlgäu",
    "district": "Landkreis Ostallgäu",
    "county": "Bayern"
  },
  {
    "sign": "OB",
    "region": "OBerhausen",
    "district": "Stadt Oberhausen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "OBB",
    "region": "OBernBurg",
    "district": "Landkreis Miltenberg",
    "county": "Bayern"
  },
  {
    "sign": "OBG",
    "region": "OsterBurG",
    "district": "Landkreis Stendal",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "OC",
    "region": "OsChersleben",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "OCH",
    "region": "OCHsenfurt",
    "district": "Landkreis Würzburg",
    "county": "Bayern"
  },
  {
    "sign": "OD",
    "region": "OlDesloe",
    "district": "Kreis Stormarn",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "OE",
    "region": "OlpE",
    "district": "Kreis Olpe",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "OF",
    "region": "OFfenbach",
    "district": "Landkreis Offenbach und Stadt Offenbach am Main",
    "county": "Hessen"
  },
  {
    "sign": "OG",
    "region": "OffenburG",
    "district": "Ortenaukreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "OH",
    "region": "OstHolstein",
    "district": "Kreis Ostholstein",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "OHA",
    "region": "Osterode am HArz",
    "district": "Landkreis Göttingen",
    "county": "Niedersachsen"
  },
  {
    "sign": "ÖHR",
    "region": "ÖHRingen",
    "district": "Hohenlohekreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "OHV",
    "region": "OberHaVel",
    "district": "Landkreis Oberhavel",
    "county": "Brandenburg"
  },
  {
    "sign": "OHZ",
    "region": "OsterHolZ",
    "district": "Landkreis Osterholz",
    "county": "Niedersachsen"
  },
  {
    "sign": "OK",
    "region": "OhreKreis",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "OL",
    "region": "OLdenburg",
    "district": "Landkreis Oldenburg, Stadt Oldenburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "OP",
    "region": "OPladen",
    "district": "Stadt Leverkusen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "OPR",
    "region": "OstPrignitz, Ruppin",
    "district": "Landkreis Ostprignitz-Ruppin",
    "county": "Brandenburg"
  },
  {
    "sign": "OS",
    "region": "OSnabrück",
    "district": "Stadt Osnabrück, Landkreis Osnabrück",
    "county": "Niedersachsen"
  },
  {
    "sign": "OSL",
    "region": "OberSpreewald, Lausitz",
    "district": "Landkreis Oberspreewald-Lausitz",
    "county": "Brandenburg"
  },
  {
    "sign": "OVI",
    "region": "OberVIechtach",
    "district": "Landkreis Schwandorf",
    "county": "Bayern"
  },
  {
    "sign": "OVL",
    "region": "OberVogtLand",
    "district": "Vogtlandkreis",
    "county": "Sachsen"
  },
  {
    "sign": "OVP",
    "region": "OstVorPommern",
    "district": "Landkreis Ostvorpommern",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "OZ",
    "region": "OschatZ",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "P",
    "region": "Potsdam",
    "district": "Stadt Potsdam",
    "county": "Brandenburg"
  },
  {
    "sign": "PA",
    "region": "PAssau",
    "district": "Passau",
    "county": "Bayern"
  },
  {
    "sign": "PAF",
    "region": "PfAFfenhofen",
    "district": "Landkreis Pfaffenhofen an der Ilm",
    "county": "Bayern"
  },
  {
    "sign": "PAN",
    "region": "PfArrkircheN",
    "district": "Landkreis Rottal-Inn",
    "county": "Bayern"
  },
  {
    "sign": "PAR",
    "region": "PARsberg",
    "district": "Landkreis Neumarkt in der Oberpfalz, Landkreis Kelheim",
    "county": "Bayern"
  },
  {
    "sign": "PB",
    "region": "PaderBorn",
    "district": "Kreis Paderborn",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "PCH",
    "region": "ParCHim",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "PE",
    "region": "PEine",
    "district": "Landkreis Peine",
    "county": "Niedersachsen"
  },
  {
    "sign": "PEG",
    "region": "PEGnitz",
    "district": "Landkreis Forchheim, Landkreis Nürnberger Land, Landkreis Bayreuth",
    "county": "Bayern"
  },
  {
    "sign": "PF",
    "region": "PForzheim",
    "district": "Pforzheim",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "PI",
    "region": "PInneberg",
    "district": "Kreis Pinneberg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "PIR",
    "region": "PIRna",
    "district": "Landkreis Sächsische Schweiz-Osterzgebirge",
    "county": "Sachsen"
  },
  {
    "sign": "PL",
    "region": "PLauen",
    "district": "Vogtlandkreis",
    "county": "Sachsen"
  },
  {
    "sign": "PLÖ",
    "region": "PLÖn",
    "district": "Kreis Plön",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "PM",
    "region": "Potsdam, Mittelmark",
    "district": "Landkreis Potsdam-Mittelmark",
    "county": "Brandenburg"
  },
  {
    "sign": "PN",
    "region": "PößNeck",
    "district": "Saale-Orla-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "PR",
    "region": "PRignitz",
    "district": "Landkreis Prignitz",
    "county": "Brandenburg"
  },
  {
    "sign": "PRÜ",
    "region": "PRÜm",
    "district": "Eifelkreis Bitburg-Prüm",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "PS",
    "region": "PirmaSens",
    "district": "Stadt Pirmasens, Landkreis Südwestpfalz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "PW",
    "region": "PaseWalk",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "PZ",
    "region": "PrenZlau",
    "district": "Landkreis Uckermark",
    "county": "Brandenburg"
  },
  {
    "sign": "QFT",
    "region": "QuerFurT",
    "district": "Saalekreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "QLB",
    "region": "QuedLinBurg",
    "district": "Landkreis Harz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "R",
    "region": "Regensburg",
    "district": "Landkreis Regensburg, Stadt Regensburg",
    "county": "Bayern"
  },
  {
    "sign": "RA",
    "region": "Württemberg",
    "district": "Landkreis Rastatt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "RC",
    "region": "ReiChenbach",
    "district": "Vogtlandkreis",
    "county": "Sachsen"
  },
  {
    "sign": "RD",
    "region": "RenDsburg",
    "district": "Kreis Rendsburg-Eckernförde",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "RDG",
    "region": "Ribnitz-DamGarten",
    "district": "Landkreis Vorpommern-Rügen",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "RE",
    "region": "REcklinghausen",
    "district": "Kreis Recklinghausen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "REG",
    "region": "REGen",
    "district": "Landkreis Regen",
    "county": "Bayern"
  },
  {
    "sign": "REH",
    "region": "REHau",
    "district": "Landkreis Hof, Landkreis Wunsiedel",
    "county": "Bayern"
  },
  {
    "sign": "REI",
    "region": "REIchenhall",
    "district": "Landkreis Berchtesgadener Land",
    "county": "Bayern"
  },
  {
    "sign": "RG",
    "region": "Riesa, Großenhain",
    "district": "Landkreis Meißen",
    "county": "Sachsen"
  },
  {
    "sign": "RH",
    "region": "RotH",
    "district": "Landkreis Roth",
    "county": "Bayern"
  },
  {
    "sign": "RI",
    "region": "RInteln",
    "district": "Landkreis Schaumburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "RID",
    "region": "RIeDenburg",
    "district": "Landkreis Kelheim",
    "county": "Bayern"
  },
  {
    "sign": "RIE",
    "region": "RIEsa",
    "district": "Landkreis Meißen",
    "county": "Sachsen"
  },
  {
    "sign": "RL",
    "region": "RochLitz",
    "district": "Landkreis Mittelsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "RM",
    "region": "Röbel/Müritz",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "RN",
    "region": "RatheNow",
    "district": "Landkreis Havelland",
    "county": "Brandenburg"
  },
  {
    "sign": "RO",
    "region": "ROsenheim",
    "district": "Stadt Rosenheim, Landkreis Rosenheim",
    "county": "Bayern"
  },
  {
    "sign": "ROD",
    "region": "RODing",
    "district": "Landkreis Cham, Landkreis Schwandorf",
    "county": "Bayern"
  },
  {
    "sign": "ROF",
    "region": "ROtenburg an der Fulda",
    "district": "Landkreis Hersfeld-Rotenburg",
    "county": "Hessen"
  },
  {
    "sign": "ROK",
    "region": "ROcKenhausen",
    "district": "Donnersbergkreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "ROL",
    "region": "ROttenburg an der Laaber",
    "district": "Landkreis Landshut, Landkreis Kelheim",
    "county": "Bayern"
  },
  {
    "sign": "ROS",
    "region": "ROStock",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "ROT",
    "region": "ROthenburg ob der Tauber",
    "district": "Landkreis Ansbach",
    "county": "Bayern"
  },
  {
    "sign": "ROW",
    "region": "ROtenburg (Wümme)",
    "district": "Landkreis Rotenburg (Wümme)",
    "county": "Niedersachsen"
  },
  {
    "sign": "RP",
    "region": "Rhein-Pfalz",
    "district": "Rhein-Pfalz-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "RS",
    "region": "RemScheid",
    "district": "Stadt Remscheid",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "RSL",
    "region": "RosSLau",
    "district": "Stadt Dessau-Roßlau",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "RT",
    "region": "ReuTlingen",
    "district": "Landkreis Reutlingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "RU",
    "region": "RUdolstadt",
    "district": "Landkreis Saalfeld-Rudolstadt",
    "county": "Thüringen"
  },
  {
    "sign": "RÜD",
    "region": "RÜDesheim",
    "district": "Rheingau-Taunus-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "RÜG",
    "region": "RÜGen",
    "district": "Landkreis Vorpommern-Rügen",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "RV",
    "region": "RaVensburg",
    "district": "Landkreis Ravensburg",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "RW",
    "region": "RottWeil",
    "district": "Landkreis Rottweil",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "RZ",
    "region": "RatZeburg",
    "district": "Kreis Herzogtum Lauenburg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "S",
    "region": "Stuttgart",
    "district": "Stadt Stuttgart",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "SAB",
    "region": "SAarBurg",
    "district": "Landkreis Trier-Saarburg",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "SAD",
    "region": "SchwAnDorf",
    "district": "Landkreis Schwandorf",
    "county": "Bayern"
  },
  {
    "sign": "SAN",
    "region": "StAdtsteiNach",
    "district": "Landkreis Hof, Landkreis Kulmbach, Landkreis Kronach",
    "county": "Bayern"
  },
  {
    "sign": "SAW",
    "region": "SAlzWedel",
    "district": "Altmarkkreis Salzwedel",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SB",
    "region": "SaarBrücken",
    "district": "Regionalverband Saarbrücken",
    "county": "Saarland"
  },
  {
    "sign": "SBG",
    "region": "StrasBurG",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "SBK",
    "region": "SchöneBecK",
    "district": "Salzlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SC",
    "region": "SChwabach",
    "district": "Stadt Schwabach",
    "county": "Bayern"
  },
  {
    "sign": "SCZ",
    "region": "SChleiZ",
    "district": "Saale-Orla-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "SDH",
    "region": "SonDersHausen",
    "district": "Kyffhäuserkreis",
    "county": "Thüringen"
  },
  {
    "sign": "SDL",
    "region": "StenDaL",
    "district": "Landkreis Stendal",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SDT",
    "region": "SchweDT",
    "district": "Landkreis Uckermark",
    "county": "Brandenburg"
  },
  {
    "sign": "SE",
    "region": "SEgeberg",
    "district": "Kreis Segeberg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "SEB",
    "region": "SEBnitz",
    "district": "Landkreis Sächsische Schweiz-Osterzgebirge",
    "county": "Sachsen"
  },
  {
    "sign": "SEE",
    "region": "SEElow",
    "district": "Landkreis Märkisch-Oderland",
    "county": "Brandenburg"
  },
  {
    "sign": "SEF",
    "region": "SchEinFeld",
    "district": "Landkreis Neustadt an der Aisch-Bad Windsheim",
    "county": "Bayern"
  },
  {
    "sign": "SEL",
    "region": "SELb",
    "district": "Landkreis Wunsiedel im Fichtelgebirge",
    "county": "Bayern"
  },
  {
    "sign": "SFB",
    "region": "SenFtenBerg",
    "district": "Landkreis Oberspreewald-Lausitz",
    "county": "Brandenburg"
  },
  {
    "sign": "SFT",
    "region": "StaßFurT",
    "district": "Salzlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SG",
    "region": "SolinGen",
    "district": "Stadt Solingen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "SGH",
    "region": "SanGerHausen",
    "district": "Landkreis Mansfeld-Südharz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SHA",
    "region": "Schwäbisch HAll",
    "district": "Landkreis Schwäbisch Hall",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "SHG",
    "region": "StadtHaGen",
    "district": "Landkreis Schaumburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "SHK",
    "region": "Saale-Holzland-Kreis",
    "district": "Saale-Holzland-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "SHL",
    "region": "SuHL",
    "district": "Stadt Suhl",
    "county": "Thüringen"
  },
  {
    "sign": "SI",
    "region": "SIegen",
    "district": "Kreis Siegen-Wittgenstein",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "SIG",
    "region": "SIGmaringen",
    "district": "Landkreis Sigmaringen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "SIM",
    "region": "SIMmern",
    "district": "Rhein-Hunsrück-Kreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "SK",
    "region": "SaaleKreis",
    "district": "Saalekreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SL",
    "region": "SchLeswig",
    "district": "Kreis Schleswig-Flensburg",
    "county": "Schleswig-Holstein"
  },
  {
    "sign": "SLE",
    "region": "SchLEiden",
    "district": "Kreis Düren, Kreis Euskirchen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "SLF",
    "region": "SaaLFeld",
    "district": "Landkreis Saalfeld-Rudolstadt",
    "county": "Thüringen"
  },
  {
    "sign": "SLK",
    "region": "SalzLandKreis",
    "district": "Salzlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "SLN",
    "region": "SchmölLN",
    "district": "Landkreis Altenburger Land",
    "county": "Thüringen"
  },
  {
    "sign": "SLS",
    "region": "SaarLouiS",
    "district": "Landkreis Saarlouis",
    "county": "Saarland"
  },
  {
    "sign": "SLÜ",
    "region": "SchLÜchtern",
    "district": "Main-Kinzig-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "SLZ",
    "region": "SaLZungen",
    "district": "Wartburgkreis",
    "county": "Thüringen"
  },
  {
    "sign": "SM",
    "region": "SchMalkalden",
    "district": "Landkreis Schmalkalden-Meiningen",
    "county": "Thüringen"
  },
  {
    "sign": "SMÜ",
    "region": "SchwabMÜnchen",
    "district": "Landkreis Augsburg",
    "county": "Bayern"
  },
  {
    "sign": "SN",
    "region": "SchweriN",
    "district": "Stadt Schwerin",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "SO",
    "region": "SOest",
    "district": "Kreis Soest",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "SOB",
    "region": "SchrOBenhausen",
    "district": "Landkreis Neuburg-Schrobenhausen",
    "county": "Bayern"
  },
  {
    "sign": "SOG",
    "region": "SchOnGau",
    "district": "Landkreis Weilheim-Schongau",
    "county": "Bayern"
  },
  {
    "sign": "SOK",
    "region": "Saale-Orla-Kreis",
    "district": "Saale-Orla-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "SÖM",
    "region": "SÖMmerda",
    "district": "Landkreis Sömmerda",
    "county": "Thüringen"
  },
  {
    "sign": "SON",
    "region": "SONneberg",
    "district": "Landkreis Sonneberg",
    "county": "Thüringen"
  },
  {
    "sign": "SP",
    "region": "SPeyer",
    "district": "Stadt Speyer",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "SPB",
    "region": "SPremBerg",
    "district": "Landkreis Spree-Neiße",
    "county": "Brandenburg"
  },
  {
    "sign": "SPN",
    "region": "SPree, Neiße",
    "district": "Landkreis Spree-Neiße",
    "county": "Brandenburg"
  },
  {
    "sign": "SR",
    "region": "StRaubing",
    "district": "Stadt Straubing, Landkreis Straubing-Bogen",
    "county": "Bayern"
  },
  {
    "sign": "SRB",
    "region": "StRausBerg",
    "district": "Landkreis Märkisch-Oderland",
    "county": "Brandenburg"
  },
  {
    "sign": "SRO",
    "region": "StadtROda",
    "district": "Saale-Holzland-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "ST",
    "region": "STeinfurt",
    "district": "Kreis Steinfurt",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "STA",
    "region": "STArnberg",
    "district": "Landkreis Starnberg",
    "county": "Bayern"
  },
  {
    "sign": "STB",
    "region": "STernBerg",
    "district": "Landkreis Ludwigslust-Parchim",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "STD",
    "region": "STaDe",
    "district": "Landkreis Stade",
    "county": "Niedersachsen"
  },
  {
    "sign": "STE",
    "region": "STaffelstEin",
    "district": "Landkreis Lichtenfels",
    "county": "Bayern"
  },
  {
    "sign": "STL",
    "region": "STolLberg",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "SU",
    "region": "SiegbUrg",
    "district": "Rhein-Sieg-Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "SUL",
    "region": "SULzbach",
    "district": "Landkreis Amberg-Sulzbach",
    "county": "Bayern"
  },
  {
    "sign": "SÜW",
    "region": "SÜdliche Weinstraße",
    "district": "Landkreis Südliche Weinstraße",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "SW",
    "region": "SchWeinfurt",
    "district": "Stadt Schweinfurt, Landkreis Schweinfurt",
    "county": "Bayern"
  },
  {
    "sign": "SWA",
    "region": "SchWAlbach",
    "district": "Rheingau-Taunus-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "SY",
    "region": "SYke",
    "district": "Landkreis Diepholz",
    "county": "Niedersachsen"
  },
  {
    "sign": "SZ",
    "region": "SalZgitter",
    "district": "Stadt Salzgitter",
    "county": "Niedersachsen"
  },
  {
    "sign": "SZB",
    "region": "SchwarZenBerg",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "TBB",
    "region": "TauBerBischofsheim",
    "district": "Main-Tauber-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "TDO",
    "region": "Torgau, Delitzsch, Oschatz",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "TE",
    "region": "TEcklenburg",
    "district": "Kreis Steinfurt",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "TET",
    "region": "TETerow",
    "district": "Landkreis Rostock",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "TF",
    "region": "Teltow, Fläming",
    "district": "Landkreis Teltow-Fläming",
    "county": "Brandenburg"
  },
  {
    "sign": "TG",
    "region": "TorGau",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "THW",
    "region": "Technisches HilfsWerk",
    "district": "Bundesanstalt Technisches Hilfswerk",
    "county": "bundesweit"
  },
  {
    "sign": "TIR",
    "region": "TIRschenreuth",
    "district": "Landkreis Tirschenreuth",
    "county": "Bayern"
  },
  {
    "sign": "TO",
    "region": "Torgau, Oschatz",
    "district": "Landkreis Nordsachsen",
    "county": "Sachsen"
  },
  {
    "sign": "TÖL",
    "region": "TÖLz",
    "district": "Landkreis Bad Tölz-Wolfratshausen",
    "county": "Bayern"
  },
  {
    "sign": "TP",
    "region": "TemPlin",
    "district": "Landkreis Uckermark",
    "county": "Brandenburg"
  },
  {
    "sign": "TR",
    "region": "TRier",
    "district": "Landkreis Trier-Saarburg und Stadt Trier",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "TS",
    "region": "TraunStein",
    "district": "Landkreis Traunstein",
    "county": "Bayern"
  },
  {
    "sign": "TÜ",
    "region": "TÜbingen",
    "district": "Landkreis Tübingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "TUT",
    "region": "TUTtlingen",
    "district": "Landkreis Tuttlingen",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "UE",
    "region": "UElzen",
    "district": "Landkreis Uelzen",
    "county": "Niedersachsen"
  },
  {
    "sign": "UEM",
    "region": "UEckerMünde",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "UFF",
    "region": "UFFenheim",
    "district": "Landkreis Neustadt an der Aisch-Bad Windsheim",
    "county": "Bayern"
  },
  {
    "sign": "UH",
    "region": "Unstrut, Hainich",
    "district": "Unstrut-Hainich-Kreis",
    "county": "Thüringen"
  },
  {
    "sign": "UL",
    "region": "ULm",
    "district": "Stadt Ulm, Alb-Donau-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "UM",
    "region": "UckerMark",
    "district": "Landkreis Uckermark",
    "county": "Brandenburg"
  },
  {
    "sign": "UN",
    "region": "UNna",
    "district": "Kreis Unna",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "USI",
    "region": "USIngen",
    "district": "Hochtaunuskreis",
    "county": "Hessen"
  },
  {
    "sign": "V",
    "region": "Vogtland",
    "district": "Vogtlandkreis",
    "county": "Sachsen"
  },
  {
    "sign": "VAI",
    "region": "VAIhingen",
    "district": "Landkreis Ludwigsburg",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "VB",
    "region": "VogelsBerg",
    "district": "Vogelsbergkreis",
    "county": "Hessen"
  },
  {
    "sign": "VEC",
    "region": "VEChta",
    "district": "Landkreis Vechta",
    "county": "Niedersachsen"
  },
  {
    "sign": "VER",
    "region": "VERden",
    "district": "Landkreis Verden",
    "county": "Niedersachsen"
  },
  {
    "sign": "VG",
    "region": "Vorpommern, Greifswald",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "VIB",
    "region": "VIlsBiburg",
    "district": "Landkreis Landshut, Landkreis Rottal-Inn",
    "county": "Bayern"
  },
  {
    "sign": "VIE",
    "region": "VIErsen",
    "district": "Kreis Viersen",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "VIT",
    "region": "VIechTach",
    "district": "Landkreis Regen",
    "county": "Bayern"
  },
  {
    "sign": "VK",
    "region": "VölKlingen",
    "district": "Stadt Völklingen",
    "county": "Saarland"
  },
  {
    "sign": "VOH",
    "region": "VOHenstrauß",
    "district": "Landkreis Neustadt an der Waldnaab",
    "county": "Bayern"
  },
  {
    "sign": "VR",
    "region": "Vorpommern, Rügen",
    "district": "Landkreis Vorpommern-Rügen",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "VS",
    "region": "Villingen-Schwenningen",
    "district": "Schwarzwald-Baar-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "W",
    "region": "Wuppertal",
    "district": "Stadt Wuppertal",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WA",
    "region": "WAldeck",
    "district": "Landkreis Waldeck-Frankenberg",
    "county": "Hessen"
  },
  {
    "sign": "WAF",
    "region": "WArendorF",
    "district": "Kreis Warendorf",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WAK",
    "region": "WArtburgKreis",
    "district": "Wartburgkreis",
    "county": "Thüringen"
  },
  {
    "sign": "WAN",
    "region": "WANne",
    "district": "Stadt Herne",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WAT",
    "region": "WATtenscheid",
    "district": "Stadt Bochum",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WB",
    "region": "WittenBerg",
    "district": "Landkreis Wittenberg",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "WBS",
    "region": "WorBiS",
    "district": "Landkreis Eichsfeld",
    "county": "Thüringen"
  },
  {
    "sign": "WDA",
    "region": "WerDAu",
    "district": "Landkreis Zwickau",
    "county": "Sachsen"
  },
  {
    "sign": "WE",
    "region": "WEimar",
    "district": "Stadt Weimar",
    "county": "Thüringen"
  },
  {
    "sign": "WEL",
    "region": "WEiLburg",
    "district": "Landkreis Limburg-Weilburg",
    "county": "Hessen"
  },
  {
    "sign": "WEN",
    "region": "WEideN",
    "district": "Stadt Weiden in der Oberpfalz",
    "county": "Bayern"
  },
  {
    "sign": "WER",
    "region": "WERtingen",
    "district": "Landkreis Dillingen an der Donau, Landkreis Augsburg",
    "county": "Bayern"
  },
  {
    "sign": "WES",
    "region": "WESel",
    "district": "Kreis Wesel",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WF",
    "region": "WolFenbüttel",
    "district": "Landkreis Wolfenbüttel",
    "county": "Niedersachsen"
  },
  {
    "sign": "WHV",
    "region": "WilhelmsHaVen",
    "district": "Stadt Wilhelmshaven",
    "county": "Niedersachsen"
  },
  {
    "sign": "WI",
    "region": "WIesbaden",
    "district": "Stadt Wiesbaden",
    "county": "Hessen"
  },
  {
    "sign": "WIL",
    "region": "WIttLich",
    "district": "Landkreis Bernkastel-Wittlich",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "WIS",
    "region": "WISmar",
    "district": "Landkreis Nordwestmecklenburg",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "WIT",
    "region": "WITten",
    "district": "Ennepe-Ruhr-Kreis",
    "county": "Nordrhein-Westfalen"
  },
  {
    "sign": "WIZ",
    "region": "WItZenhausen",
    "district": "Werra-Meißner-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "WK",
    "region": "WittstocK",
    "district": "Landkreis Ostprignitz-Ruppin",
    "county": "Brandenburg"
  },
  {
    "sign": "WL",
    "region": "Winsen (Luhe)",
    "district": "Landkreis Harburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "WLG",
    "region": "WoLGast",
    "district": "Landkreis Vorpommern-Greifswald",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "WM",
    "region": "WeilheiM",
    "district": "Landkreis Weilheim-Schongau",
    "county": "Bayern"
  },
  {
    "sign": "WMS",
    "region": "WolMirStedt",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "WN",
    "region": "WaiblingeN",
    "district": "Rems-Murr-Kreis",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "WND",
    "region": "WeNDel",
    "district": "Landkreis St. Wendel",
    "county": "Saarland"
  },
  {
    "sign": "WO",
    "region": "WOrms",
    "district": "Stadt Worms",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "WOB",
    "region": "WOlfsBurg",
    "district": "Stadt Wolfsburg",
    "county": "Niedersachsen"
  },
  {
    "sign": "WOH",
    "region": "WOlfHagen",
    "district": "Landkreis Kassel",
    "county": "Hessen"
  },
  {
    "sign": "WOL",
    "region": "WOLfach",
    "district": "Ortenaukreis, Landkreis Freudenstadt",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "WOR",
    "region": "WOlfRatshausen",
    "district": "Landkreis Bad Tölz-Wolfratshausen, Landkreis München, Landkreis Starnberg",
    "county": "Bayern"
  },
  {
    "sign": "WOS",
    "region": "WOlfStein",
    "district": "Landkreis Freyung-Grafenau",
    "county": "Bayern"
  },
  {
    "sign": "WR",
    "region": "WernigeRode",
    "district": "Landkreis Harz",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "WRN",
    "region": "WaReN",
    "district": "Landkreis Mecklenburgische Seenplatte",
    "county": "Mecklenburg-Vorpommern"
  },
  {
    "sign": "WS",
    "region": "WasSerburg",
    "district": "Landkreis Rosenheim",
    "county": "Bayern"
  },
  {
    "sign": "WSF",
    "region": "WeisSenFels",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "WST",
    "region": "WesterSTede",
    "district": "Landkreis Ammerland",
    "county": "Niedersachsen"
  },
  {
    "sign": "WSW",
    "region": "WeisSWasser",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "WT",
    "region": "WaldshuT",
    "district": "Landkreis Waldshut",
    "county": "Baden-Württemberg"
  },
  {
    "sign": "WTL",
    "region": "WitTLage",
    "district": "Landkreis Osnabrück",
    "county": "Niedersachsen"
  },
  {
    "sign": "WTM",
    "region": "WitTMund",
    "district": "Landkreis Wittmund",
    "county": "Niedersachsen"
  },
  {
    "sign": "WÜ",
    "region": "WÜrzburg",
    "district": "Landkreis Würzburg, Stadt Würzburg",
    "county": "Bayern"
  },
  {
    "sign": "WUG",
    "region": "WeißenbUrG",
    "district": "Landkreis Weißenburg-Gunzenhausen",
    "county": "Bayern"
  },
  {
    "sign": "WÜM",
    "region": "WaldMÜnchen",
    "district": "Landkreis Cham",
    "county": "Bayern"
  },
  {
    "sign": "WUN",
    "region": "WUNsiedel",
    "district": "Landkreis Wunsiedel",
    "county": "Bayern"
  },
  {
    "sign": "WUR",
    "region": "WURzen",
    "district": "Landkreis Leipzig",
    "county": "Sachsen"
  },
  {
    "sign": "WW",
    "region": "WesterWald",
    "district": "Westerwaldkreis",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "WZ",
    "region": "WetZlar",
    "district": "Stadt Wetzlar",
    "county": "Hessen"
  },
  {
    "sign": "WZL",
    "region": "WanZLeben",
    "district": "Landkreis Börde",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "Z",
    "region": "Zwickau",
    "district": "Landkreis Zwickau",
    "county": "Sachsen"
  },
  {
    "sign": "ZE",
    "region": "ZErbst",
    "district": "Landkreis Anhalt-Bitterfeld",
    "county": "Sachsen-Anhalt"
  },
  {
    "sign": "ZEL",
    "region": "ZELl",
    "district": "Landkreis Cochem-Zell",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "ZI",
    "region": "ZIttau",
    "district": "Landkreis Görlitz",
    "county": "Sachsen"
  },
  {
    "sign": "ZIG",
    "region": "ZIeGenhain",
    "district": "Schwalm-Eder-Kreis",
    "county": "Hessen"
  },
  {
    "sign": "ZP",
    "region": "ZschoPau",
    "district": "Erzgebirgskreis",
    "county": "Sachsen"
  },
  {
    "sign": "ZR",
    "region": "ZeulenRoda",
    "district": "Landkreis Greiz",
    "county": "Thüringen"
  },
  {
    "sign": "ZW",
    "region": "ZWeibrücken",
    "district": "Stadt Zweibrücken, Landkreis Südwestpfalz",
    "county": "Rheinland-Pfalz"
  },
  {
    "sign": "ZZ",
    "region": "ZeitZ",
    "district": "Burgenlandkreis",
    "county": "Sachsen-Anhalt"
  }
]