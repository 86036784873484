import * as React from 'react';

export default class Tracking extends React.Component {

    render() {
        var page = document.location.pathname;

        if (window.ga) {
            
            window.ga('set', 'page', page);
            window.ga('send', 'pageview');    
        } else if (window.gtag) {
            
            window.gtag('config', 'G-35DYH91D80', {
                'page_title' : page,
                'page_path': page
              });
        }

//        gtag('event', 'login', {'method': 'Google'});

        return (
            <span />
        );
    }
}
