import React from 'react';
import Answers from './Answers';
import Container from '@material-ui/core/Container';
import ButtonSound from './ButtonSound';

class Question extends React.Component {

    state = {};
    constructor(props) {

        super(props);

        this.state = {
            question: this.props.question,
            showGoToButton: false
        }
    }

    onClickAnswer = (answer, index) => {

        let question = this.state.question;
        for (let i = 0; i < question.answers.length; i++) {

            question.answers[i].selected = false;
        }
        question.answers[index].selected = answer.selected ? false : true;
        this.setState({
            question: question,
            showGoToButton: question.answers[index].selected
        });
    }

    render() {

        const question = this.state.question.question;
        const answers = this.state.question.answers;
        const answerType = this.state.question.type;

        return (
            <div className="Question">
                <Container maxWidth="sm">
                    <h2>{question}</h2>
                    <Answers answers={answers} answerType={answerType} onClickAnswer={this.onClickAnswer} />
                    {this.state.showGoToButton === true &&
                        <div className="goto-button">
                            <ButtonSound sound="clack" color="secondary" onClick={() => this.props.clickProof(this.state.question)} label="Weiter >>" />
                        </div>}
                </Container>
            </div>
        );
    }
}

export default Question;
