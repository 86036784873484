import * as React from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, WhatsappIcon } from 'react-share';

export default class Sharer extends React.Component {

    render() {

        const isWeb = (window.navigator.userAgent.indexOf("flutter_app") !== -1) ? false : true;

        if (isWeb === false) {
            return (null)
        }

        const shareUrl = this.props.url;
        const title = this.props.title;
        const mailSubject = 'Das Autokennzeichen Quiz!';
        const mailBody = 'Hallo,\ndu musst unbedingt das Quiz probieren!\n' +
            title + ' \n' +
            'Du findest es hier: ' + shareUrl +
            '' +
            '';

        return (
            <div className="share-wrapper">
                <div className="sharer">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round="true" />
                    </FacebookShareButton>
                    {/* <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="Demo__some-network__share-button"
                    >
                        <WhatsappIcon size={32} round="true" />
                    </WhatsappShareButton> */}

                    <a href={`whatsapp://send?text=${title}: ${shareUrl}`} data-action="share/whatsapp/share"><WhatsappIcon size={32} round="true" /></a>

                    <EmailShareButton
                        subject={mailSubject}
                        body={mailBody}
                        url={shareUrl}
                    >
                        <EmailIcon size={32} round="true" />
                    </EmailShareButton>
                </div>
                <div style={{ clear: 'both' }} />
            </div>
        );
    }
}