import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { QUESTIONS } from "../questions";

class ViewSearch extends React.Component {

    state = {signs: {}, didLoad: false};
    componentWillMount() {

        this.walkThroughLib();
    }

    walkThroughLib() {

        let signs = {}
        for (let i = 0; i < QUESTIONS.length; i++) {

            const q = QUESTIONS[i];

            if (!signs[q.sign]) {
                signs[q.sign] = []
            }
            signs[q.sign].push(q)

        }

        this.setState({
            signs: signs,
            didLoad: true
        })
    }

    render() {

        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });

        let district = {}
        const search = this.props.match.params.slug.toUpperCase();

        if (this.state.signs[search] && this.state.didLoad === true) {
            district = this.state.signs[search][0]
        }
        if (!district.sign) {
            return (
                <div>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h2" component="h2">
                        Die Suche nach {search} war leider nicht erfolgreich
                    </Typography>
                    
                    </CardContent>
                </Card>


                <Container maxWidth="sm">
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                    </div>
                </Container>
            </div>
            )
        }

        return (
            <div>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h2" component="h2">
                        Suche nach {search}
                    </Typography>
                        <Typography variant="body2" component="p">
                            <strong>{district.question}</strong>
                            <br /><br />
                            <strong>Region</strong>: <span>{district.region}</span><br />
                            <strong>Kennzeichen</strong>: <span>{district.sign}</span><br />
                            <strong>Kreis</strong>: <span>{district.district}</span><br />
                            <strong>Bundesland</strong>: <span>{district.county}</span><br />
                        </Typography>
                    </CardContent>
                </Card>


                <Container maxWidth="sm">
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ViewSearch;
