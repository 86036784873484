import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StorageData from './StorageData';


class QuestionInfo extends React.Component {

    render() {

        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });
        const question = this.props.question;

        const num =  (new StorageData()).findQuestion(question.question);

        return (
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography variant="h6" component="h6">
                        Infos zur Frage:
                    </Typography>
                    <Typography variant="body2" component="p">
                        <strong>{question.question}</strong>
                        <br /><br />
                        <strong>Region</strong>: <span>{question.region}</span><br />
                        <strong>Kennzeichen</strong>: <span>{question.sign}</span><br />
                        <strong>Kreis</strong>: <span>{question.district}</span><br />
                        <strong>Bundesland</strong>: <span>{question.county}</span><br />

                        {num > 0 && <div>
                            <strong>Diese Frage wurde bereits {num} Mal falsch beantwortet ;-)</strong>
                        </div>}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default QuestionInfo;
