import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import { QUESTIONS } from "../questions";

class ViewRegister extends React.Component {

    state = {
        districts: {},
        counties: {},
        cities: {}
    }

    componentWillMount() {

        this.walkThroughLib();
    }

    walkThroughLib() {

        let counties = {}
        for (let i = 0; i < QUESTIONS.length; i++) {

            const q = QUESTIONS[i];

            if (!counties[q.county]) {
                counties[q.county] = []
            }
            counties[q.county].push(q)

        }

        this.setState({
            counties: counties
        })
    }

    renderRegister() {
        let countyCodes = []
        for (let county in this.state.counties) {

            if (county === 'bundesweit') {
                continue;
            }
            countyCodes.push(county);
        }


        return (
            <div>
                {countyCodes.map(county => {
                    return (
                        <div>
                            <Link to={`/register/county/${county}`}>{county}</Link>
                        </div>
                    )
                })}
            </div>
        )

        // return (
        //     <div dangerouslySetInnerHTML={{__html: countyCodes.join("<hr />")}} />
        // )
    }

    render() {
        const classes = makeStyles({
            root: {
                minWidth: 275,
            },
            bullet: {
                display: 'inline-block',
                margin: '0 2px',
                transform: 'scale(0.8)',
            },
            title: {
                fontSize: 14,
            },
            pos: {
                marginBottom: 12,
            },
        });
        return (
            <div className="ViewKennzeichen">

                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h2" component="h2">
                            Register über alle Bundesländer, ihre Kreise und Kennzeichen
                    </Typography>
                        <Typography variant="body2" component="p">
                            {this.renderRegister()}
                        </Typography>
                    </CardContent>
                </Card>
                <Container maxWidth="sm">
                    <div className="goto-button">
                        <Button variant="contained" color={"secondary"} onClick={() => this.props.history.push('/spiel/round/1')}>Spiel starten</Button>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ViewRegister;
